// freddy-react/src/services/api/v1/clientService.js
import {makeGETRequest, makePOSTRequest} from "../../utils/httpUtils";

export const fetchAllClients = (accessToken) => {
    return makeGETRequest(`/api/v1/client/list`, accessToken);
};

export const fetchAllClientDetails = async (params, accessToken) => {
    const filteredGetUrl = `/api/v1/client/list`;
    return makeGETRequest(filteredGetUrl, accessToken);
};

export const getAllClientOrganizations = async (clientId, accessToken) => {
    const filteredGetUrl = `/api/v1/client/${clientId}/organizations`;
    return makeGETRequest(filteredGetUrl, accessToken);
};

export const getAllClientAccounts = async (clientId, accessToken) => {
    const filteredGetUrl = `/api/v1/client/${clientId}/accounts/list`;
    return makeGETRequest(filteredGetUrl, accessToken);
};

export const fetchOrganizationLaboratoryAccountDetails = async (organizationId, accessToken) => {
    const filteredGetUrl = `/api/v1/${organizationId}/account/details`;
    return makeGETRequest(filteredGetUrl, accessToken);
};


export const getClientSites = async (clientId, accessToken) => {
    const filteredGetUrl = `/api/v1/client/${clientId}/account/sites`;
    return makeGETRequest(filteredGetUrl, accessToken);
};


export const linkPricesToClients = async (selectedPrices, selectedClients, accessToken) => {

    let formData = new FormData();
    formData.append('service_prices', JSON.stringify(selectedPrices));
    formData.append('selected_clients', JSON.stringify(selectedClients));

    return makePOSTRequest(`/api/v1/clients/prices/link`, formData, accessToken);
};