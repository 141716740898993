// freddy-react/src/components/clients/clients_row/ClientsRow.js
import React, {useState} from "react";
import "./ClientsRow.css";
import ActionTray from "../../modals/action_tray/ActionTray";
import ExpandCell from "../expand_cell/ExpandCell";
import OrganizationsTable from "../organizations/Organizations";
import {ActionTrayIcons, GenericIcons} from "../../../assets/icons";
import {useDispatch} from "react-redux";
import {getClientBillingDetails} from "../../../store/clients/clientBillingDetailsSlice";
import {toggleBadganatorModal} from "../../../store/badganator/badganatorSlice";
import {
    fetchAllClientAccounts,
    fetchClientSites,
    toggleClientAccountsModal,
    toggleClientSitesModal,
} from "../../../store/clients/clientsSlice";
import {fetchClientScreenersDetails} from "../../../store/screener/screenerSlice";

const ClientsRow = ({ client, userRoles }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const dispatch = useDispatch();

    const handleShowBadganator = () => {
        dispatch(toggleBadganatorModal(client.id));
    };

    const handleClientPricing = async () => {
        await dispatch(getClientBillingDetails({ clientId: client.id }));
    };

    const handleGetClientSites = async () => {
        await dispatch(fetchClientSites({ clientId: client.id }));
        dispatch(toggleClientSitesModal(true));
    };

    const handleClientAccountDetails = async () => {
        await dispatch(fetchAllClientAccounts({ clientId: client.id }));
        dispatch(toggleClientAccountsModal(true));

        if (userRoles.includes("Freddy.Public.Portal.Invite")) {
            await dispatch(fetchClientScreenersDetails({ clientId: client.id }));
        }
    };

    const iconsData = [
        { icon: ActionTrayIcons.BadgeIcon, alt: "Badge", action: handleShowBadganator, permission: "Freddy.Badganator" },
        { icon: ActionTrayIcons.DollarSignIcon, alt: "Client Details", action: handleClientPricing, permission: "Labb.Client.Pricing" },
        { icon: ActionTrayIcons.PersonIcon, alt: "Client Account Details", action: handleClientAccountDetails, permission: "Labb.Client.Management" },
        { icon: ActionTrayIcons.SendIcon, alt: "Get Client Sites", action: handleGetClientSites, permission: "Labb.Client.Management" },
        { icon: ActionTrayIcons.DesktopIcon, alt: "Go to Labb Dashboard",
            action: () => window.open(client.admin_portal_url, "_blank")
            , permission: "Labb.Client.Management" },
    ];

    const rapidServiceText =
        client.rapid_service_level === "1"
            ? "Rapid Only"
            : client.rapid_service_level === "2"
                ? "Rapid + Tox"
                : client.rapid_service_level === "3"
                    ? "Rapid + Tox + MRO (POS ONLY)"
                    : "";

    const rapidLabText =
        client.rapid_lab === "quest_diagnostics" ? "Quest" : client.rapid_lab || "";

    const toxServiceText =
        client.tox_service_level === "1"
            ? "Tox"
            : client.tox_service_level === "2"
                ? "Tox + MRO (POS ONLY)"
                : client.tox_service_level === "3"
                    ? "Tox + MRO (ALL)"
                    : "";

    const toxLabText =
        client.tox_lab === "quest_diagnostics" ? "Quest" : client.tox_lab || "";

    const renderBoolean = (value) =>
        value ? (
            <img src={GenericIcons.CheckIcon} alt="Yes" className="check-icon" />
        ) : (
            ""
        );

    return (
        <>
            <div className="client-row">
                <div className="client-cell client-company-cell">
                    <div className="client-name">{client.company}</div>
                    <div className="client-address">{client.address}</div>
                </div>
                <div className="client-cell client-services-cell">
                    <div className="service-group">
                        <div className="pill">{rapidServiceText}</div>
                        <div className="pill">{rapidLabText}</div>
                        {client.rapid_mro_name && <div className="pill">{client.rapid_mro_name}</div>}
                    </div>
                </div>
                <div className="client-cell client-services-cell">
                    <div className="service-group">
                        <div className="pill">{toxServiceText}</div>
                        <div className="pill">{toxLabText}</div>
                        {client.tox_mro_name && <div className="pill">{client.tox_mro_name}</div>}
                    </div>
                </div>
                <div className="client-cell client-additional-cell">{renderBoolean(client.dot_service)}</div>
                <div className="client-cell client-additional-cell">{renderBoolean(client.paper_chain_service)}</div>
                <div className="client-cell client-organization-string-cell">{client.organization_string || ""}</div>
                <div className="client-cell client-positive-string-cell">{client.positive_string || ""}</div>
                <div className="client-cell client-action-cell">
                    {client.has_organizations && (
                        <div className="client-expand-cell">
                            <ExpandCell isExpanded={isExpanded} toggleExpand={() => setIsExpanded(!isExpanded)} />
                        </div>
                    )}
                    <div className="client-action-tray-cell">
                        <ActionTray iconsData={iconsData} rowData={client} userRoles={userRoles} />
                    </div>
                </div>
            </div>
            {isExpanded && (
                <div className="client-expanded-row">
                    <div className="client-expanded-content">
                        <OrganizationsTable clientId={client.id} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ClientsRow;
