import "./PublicHome.css";
import React, {useEffect} from "react";
import PublicHeader from "../../../components/headers/public_header/PublicHeader";
import {useShopifyLogout} from "../../../utils/authUtils";
import LabFusionLogo from "../../../assets/logo/LabFusionLogo.png";
import SpeedSwabsLogo from "../../../assets/logo/SpeedSwabLogo.png";
import PublicFreddyLogo from "../../../assets/logo/PublicFreddyLogo.png";
import LabbLogo from "../../../assets/logo/LabbLogo.png";
import ShopifyHeader from "../../../components/headers/shopify_header/ShopifyHeader";
import FusionDashboard from "../../dashboards/fusion_dashboard/FusionDashboard";
import LabbDashboard from "../../dashboards/labb_dashboard/LabbDashboard";
import FusionFooter from "../../../components/footers/fusion_footer/FusionFooter";
import PublicFooter from "../../../components/footers/public_footer/PublicFooter";
import SpeedSwabsDashboard from "../../dashboards/speed_swab_dashboard/SpeedSwabDashboard";
import SpeedSwabsFooter from "../../../components/footers/speed_swab_footer/SpeedSwabsFooter";
import SpeedSwabsHeader from "../../../components/headers/speed_swabs_header/SpeedSwabsHeader";
import PublicFreddyFooter from "../../../components/footers/public_freddy_footer/PublicFreddyFooter";
import PublicFreddyDashboard from "../../dashboards/public_freddy_dashboard/PublicFreddyDashboard";

const PublicHomePage = () => {
    const shopifyAccessToken = sessionStorage.getItem("shopifyAccessToken");
    const hostname = window.location.hostname;
    const onLogout = useShopifyLogout();

    // Determine which logo to display
    const logoSrc =
        hostname === "fusion.labb.com" ?
            LabFusionLogo
            : hostname === "shop.speedswabs.com"
                ? SpeedSwabsLogo
                : hostname === "app.labbvision.com"
                    ? PublicFreddyLogo
                    : hostname === "reign.dev.freddy.app.labbvision.com"
                        ? LabFusionLogo
                        : hostname === "labb.dev.freddy.app.labbvision.com"
                            ? LabFusionLogo
                            : hostname === "remote.dev.freddy.app.labbvision.com"
                                ? LabFusionLogo : LabbLogo;

    // Select the appropriate dashboard component based on the domain
    const DashboardContent =
        hostname === "fusion.labb.com" ? FusionDashboard
            : hostname === "shop.speedswabs.com"
                ? SpeedSwabsDashboard
                : hostname === "app.labbvision.com"
                    ? PublicFreddyDashboard
                    : hostname === "reign.dev.freddy.app.labbvision.com"
                        ? LabbDashboard
                        : hostname === "remote.dev.freddy.app.labbvision.com"
                            ? LabbDashboard
                            : hostname === "labb.dev.freddy.app.labbvision.com"
                                ? LabbDashboard
                                : LabbDashboard;
    // Select the appropriate footer component
    const FooterComponent =
        hostname === "fusion.labb.com" ? FusionFooter
            : hostname === "shop.speedswabs.com"
                ? SpeedSwabsFooter
                : hostname === "app.labbvision.com"
                    ? PublicFreddyFooter
                    : hostname === "remote.dev.freddy.app.labbvision.com"
                        ? FusionFooter
                        : hostname === "labb.dev.freddy.app.labbvision.com"
                            ? FusionFooter
                            : hostname === "reign.dev.freddy.app.labbvision.com"
                                ? FusionFooter : PublicFooter;

    // Set document title based on domain
    const docTitle =
        hostname === "fusion.labb.com" ? "Lab Fusion | Dashboard"
            : hostname === "shop.speedswabs.com"
                ? "SpeedSwabs | Dashboard"
                : hostname === "app.labbvision.com"
                    ? "Freddy Vision | Dashboard"
                    : hostname === "remote.dev.freddy.app.labbvision.com"
                        ? "Freddy | Dashboard"
                        : hostname === "labb.dev.freddy.app.labbvision.com"
                            ? "Freddy | Dashboard"
                            : hostname === "reign.dev.freddy.app.labbvision.com"
                                ? "Freddy | Dashboard" : "Labb | Dashboard";

    useEffect(() => {
        document.title = docTitle;
    }, [docTitle]);

    return (
        <div className="public-homepage">
            {shopifyAccessToken ? (
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            ) : hostname === "shop.speedswabs.com" ? (
                <SpeedSwabsHeader logoSrc={logoSrc} onLogout={onLogout}/>
            ) : hostname === "app.labbvision.com" ? (
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            ) : hostname === "reign.dev.freddy.app.labbvision.com" ? (
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            ) : hostname === "labb.dev.freddy.app.labbvision.com" ? (
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            ) : hostname === "remote.dev.freddy.app.labbvision.com" ? (
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            ) : (
                <PublicHeader logoSrc={logoSrc} onLogout={onLogout}/>
            )}
            <main className="dashboard-content">
                <DashboardContent/>
            </main>
            <FooterComponent/>
        </div>
    );
};

export default PublicHomePage;
