import "./ShopifyHome.css";
import React from 'react';
import LabFusionLogo from '../../../assets/logo/LabFusionLogo.png';
import LabbLogo from '../../../assets/logo/LabbLogo.png';
import ShopifyHeader from '../../../components/headers/shopify_header/ShopifyHeader';
import {useShopifyLogout} from "../../../utils/authUtils";
import ShopifyCustomerDetails from "../../../components/user/cusotmer_details/ShopifyCustomerDetails";

const ShopifyHomePage = () => {
    const hostname = window.location.hostname;
    const logoSrc = hostname === 'fusion.labb.com' ? LabFusionLogo : LabbLogo;
    const onLogout = useShopifyLogout();
    const isLabFusion = hostname === 'fusion.labb.com';

    document.title = isLabFusion ? "Lab Fusion | Home" : "Labb | Home";

    return (
        <div className="shopify-home-container">
            <div className="shopify-header-container">
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout} />
            </div>
            <div>
                <ShopifyCustomerDetails />
            </div>
            <div className="shopify-text-container">
                <p>Welcome to {isLabFusion ? "Lab Fusion" : "Labb"}!</p>
                <p>Use the icons in the upper-right corner to navigate through the platform.</p>
                <p>
                    If you don't see any icons, double-check that your {isLabFusion ? "Lab Fusion" : "Labb"} email matches the one linked to the {isLabFusion ? "Lab Fusion" : "Labb"} system.
                </p>
                <p>
                    You may also need to reach out to your primary contact in {isLabFusion ? "Lab Fusion" : "Labb"} to confirm your permissions.
                </p>
            </div>
        </div>
    );
};

export default ShopifyHomePage;
