import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {fetchSpecimenNotes} from "../../services/api/v1/screenNotesService";

export const getSpecimenNotes = createAsyncThunk(
    "specimenNotes/getSpecimenNotes",
    async ({ startDate, endDate }, { rejectWithValue }) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            const formData = new FormData();
            formData.append("start_date", startDate);
            formData.append("end_date", endDate);

            const response = await fetchSpecimenNotes(formData, accessToken);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


const screenNotesSlice = createSlice({
    name: "notes",
    initialState: {
        notes: [],
        isScreenNotesModalOpen: false,
        status: "idle",
        error: null,
    },
    reducers: {
        closeSpecimenNotesModal: (state) => {
            state.isScreenNotesModalOpen = false;
        },
        openSpecimenNotesModal: (state) => {
            state.isScreenNotesModalOpen = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSpecimenNotes.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSpecimenNotes.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notes = action.payload || [];
            })
            .addCase(getSpecimenNotes.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const { closeSpecimenNotesModal, openSpecimenNotesModal } = screenNotesSlice.actions;
export default screenNotesSlice.reducer;
