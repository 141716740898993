import React from "react";
import "./NoteList.css";
import formatDate from "../../../../utils/dateUtils";

const NoteList = ({ note }) => {
    return (
        <div className="note-container">
            <div className="note-content">{note.note_content}</div>
            <div className="note-meta">
                <div className="note-author">Specimen ID: {note.specimen_id}</div>
                <div className="note-author">Author: {note.note_author}</div>
                <div className="note-date">Collected On: {formatDate(note.collected_on)}</div>
                <div className="note-date">Note Created On: {formatDate(note.note_created_on)}</div>
                <div className="note-client">Client: {note.client_name}</div>
                <div className="note-location">Location: {note.location_name}</div>
                <div className="note-organization">Organization: {note.organization_name}</div>
            </div>
        </div>
    );
};

export default NoteList;