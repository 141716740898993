// freddy-react/src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";

import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./utils/authConfig";
import {MsalProvider} from "@azure/msal-react";

/**
 * Represents an instance of MSAL (Microsoft Authentication Library) PublicClientApplication.
 * This class is used for initializing and managing authentication flows for public client applications.
 *
 * @constructor
 * @param {Object} msalConfig - Configuration object containing settings for the MSAL instance.
 */

console.log(msalConfig);

export const msalInstance = new PublicClientApplication(msalConfig);


/**
 * Initialize a new ReactDOM root container at the specified DOM element with the id 'root'.
 *
 * @type {ReactDOM}
 */
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <MsalProvider instance={msalInstance}>
        <App instance={msalInstance}/>
    </MsalProvider>
);


reportWebVitals();

