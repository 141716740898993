// freddy-react/src/services/api/v1/noteService.js

import { makeGETRequest, makePOSTRequest } from '../../utils/httpUtils';

export const fetchAllNotifications = (accessToken) => {
    return makeGETRequest('/api/v1/notifications/list', accessToken);
};

export const setNewNotificationEvent = (accessToken) => {
    return makeGETRequest('/api/v1/notifications/add', accessToken);
};

export const fetchSaveScreenNotification = async (formData, accessToken) => {
    return await makePOSTRequest(`/api/v1/specimens/notification/new`, formData, accessToken);
};

export const fetchAcknowledgeScreenNotification = async (formData, accessToken) => {
    return await makePOSTRequest(`/api/v1/specimens/notification/acknowledge`, formData, accessToken);
};
