// freddy-react/src/services/api/v1/questServicesService.js
import {makePOSTRequest} from "../../utils/httpUtils";

export const requestQuestOrders = (accessToken, questOrder) => {

    let formData = new FormData();

    // Serialize questOrder and append to form data
    formData.append('order_quest_supplies', JSON.stringify(questOrder));

    console.log("Serialized Order Request:", formData);


    return makePOSTRequest('/api/v1/automation/client/supplies/new', formData, accessToken);
};