import React, {useEffect, useState} from "react";
import "./ManageShopifyPage.css";
import {PrimaryButton} from "../../../components/buttons/primary_button/PrimaryButton";
import AdminHeader from "../../../components/headers/./admin_header/AdminHeader";
import ShopifyManageCustomers from "../../../components/shopify/manage/customers/ShopifyManageCustomers";
import ShopifyManageProducts from "../../../components/shopify/manage/products/ShopifyManageProducts";
import ShopifyManageCompanies from "../../../components/shopify/manage/companies/ShopifyManageCompanies";
import ShopifyManageOrders from "../../../components/shopify/manage/orders/ShopifyManageOrders";
import ShopifyManageServices from "../../../components/shopify/manage/services/ShopifyManageServices";
import {getShopifyAdminAuth} from "../../../store/external/shopifyAuthSlice";
import {initiateShopifyAdminOAuth} from "../../../utils/authUtils";
import {useDispatch, useSelector} from "react-redux";


const ManageShopifyPage = () => {
    const [activeTab, setActiveTab] = useState('customers'); // default tab
    const dispatch = useDispatch();
    const shopifyAccessToken = useSelector(state => state.shopifyAuth.access_token);
    const shopifyTokenExpiry = sessionStorage.getItem("shopifyTokenExpiry");

    /**
     * Handle Shopify authentication by initiating OAuth and then obtaining the access token.
     */
    const handleShopifyAuth = async () => {
        const now = new Date().getTime();
        const grantToken = sessionStorage.getItem("shopifyAdminGrantToken");

        try {
            if (!shopifyAccessToken || !shopifyTokenExpiry || now >= parseInt(shopifyTokenExpiry, 10)) {
                // If there's a grant token, use it to get the admin access token
                if (grantToken) {
                    const result = await dispatch(getShopifyAdminAuth({grantToken}));
                    if (getShopifyAdminAuth.fulfilled.match(result)) {
                        sessionStorage.setItem("shopifyTokenExpiry", (now + 3600 * 1000).toString()); // Assuming token is valid for 1 hour
                    }
                } else {
                    // If no grant token is available, initiate the OAuth flow
                    initiateShopifyAdminOAuth();
                }
            } else {
                console.log("Shopify authentication token is still valid.");
            }
        } catch (error) {
            console.error("Error during Shopify authentication:", error);
        }
    };

    // Run authentication on component mount
    useEffect(() => {
        handleShopifyAuth();
    }, []);


    const renderActiveTab = () => {
        switch (activeTab) {
            case 'customers':
                return <ShopifyManageCustomers/>;
            case 'products':
                return <ShopifyManageProducts/>;
            case 'services':
                return <ShopifyManageServices/>;
            case 'companies':
                return <ShopifyManageCompanies/>;
            case 'orders':
                return <ShopifyManageOrders/>;
            default:
                return <ShopifyManageCustomers/>;
        }
    };

    return (
        <div>
            <div>
                <AdminHeader/>
            </div>
            <div className="shopify-tabs">
                <PrimaryButton onClick={() => setActiveTab('customers')}>Customers</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('products')}>Products</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('services')}>Services</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('companies')}>Companies</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('orders')}>Orders</PrimaryButton>
            </div>

            <div className="shopify-content">
                {renderActiveTab()}
            </div>
        </div>
    );
};

export default ManageShopifyPage;
