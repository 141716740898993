import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PricesModel.css';
import PricesRow from './prices_row/PricesRow';
import { IconButton } from '../../buttons/icon_button/IconButton';
import { GenericIcons, PricingIcons } from "../../../assets/icons";
import { clearServicePrices, linkClientPricesToService } from "../../../store/prices/servicePricesSlice";
import { getAllClients } from "../../../store/clients/clientsSlice";
import FastSelect from "../../clients/fast_select/FastSelect";

const PricesModel = () => {
    const isVisible = useSelector(state => state.servicePrices.showModal);
    const servicePrices = useSelector(state => state.servicePrices.prices);
    const clients = useSelector(state => state.clients.clientData);
    const [selectedClients, setSelectedClients] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!clients.length) {
            dispatch(getAllClients());
        }
    }, [dispatch, clients.length]);

    const clientOptions = clients.map(client => ({
        label: client.company,
        value: client.id
    }));

    if (!isVisible) return null;

    const handleClose = () => {
        dispatch(clearServicePrices());
        setSelectedClients([]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const clientIds = selectedClients.map(client => client.value);
        dispatch(linkClientPricesToService({ selectedPrices: servicePrices, selectedClients: clientIds }));
        handleClose();
    };

    return (
        <div className="prices-modal-overlay">
            <div className="prices-modal">
                <div className="prices-modal-header">
                    <h2>Manage Prices</h2>
                    <IconButton
                        defaultIcon={GenericIcons.CloseIcon}
                        hoverIcon={GenericIcons.ExitIcon}
                        alt="Close"
                        onClick={handleClose}
                    />
                </div>
                <div className="prices-modal-content">
                    <form onSubmit={handleSubmit}>
                        <div className="prices-header">
                            <FastSelect
                                options={clientOptions}
                                selectedClients={selectedClients}
                                setSelectedClients={setSelectedClients}
                            />
                        </div>
                        <div className="prices-grid">
                            {servicePrices.map((price, index) => (
                                <PricesRow key={index} price={price}/>
                            ))}
                        </div>
                    </form>
                </div>
                <div className="prices-save-action">
                    <IconButton
                        defaultIcon={PricingIcons.SaveIcon}
                        hoverIcon={PricingIcons.SaveIcon}
                        onClick={handleSubmit}
                        alt="Save"
                    />
                </div>
            </div>
        </div>
    );
};

export default PricesModel;
