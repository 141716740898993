// freddy-react/src/store/autobot/screeners/screenersSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getScreensByClientId} from "../../services/api/v1/clientScreensService";

const initialState = {
    clientScreensData: [],
    isLoading: false,

};


export const fetchScreensByClientId = createAsyncThunk(
    'clientScreens/getScreensByClientId',
    async ({selectedClientAccountId, filters = {} }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("shopifyAccessToken");

        const formData = new FormData();


        formData.append('start_date', filters.date_range?.start_date);
        formData.append('end_date', filters.date_range?.end_date);

        try {
            // Use the updated backend request function
            const response = await getScreensByClientId(selectedClientAccountId, formData, accessToken);

            console.log(response)
            return response; // Assuming `data` contains the response payload
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || "Failed to fetch client screens");
        }
    }
);

const clientScreensSlice = createSlice({
    name: 'clientScreens',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchScreensByClientId.pending]: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        [fetchScreensByClientId.fulfilled]: (state, action) => {
            state.clientScreensData = action.payload;
            state.isLoading = false;
        },
        [fetchScreensByClientId.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        },
    }
});

export const {  } = clientScreensSlice.actions;
export default clientScreensSlice.reducer;
