import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchDeviceIds} from "../../services/api/v1/screenService";
import {fetchAllClients} from "../../services/api/v1/clientService";
import {fetchAllLocations} from "../../services/api/v1/locationService";
import {fetchStatuses} from "../../services/api/v1/statusesService";
import {fetchServiceLevels} from "../../services/api/v1/serviceLevelService";
import {fetchLssProducts} from "../../services/api/v1/productsService";

export const getDeviceIds = createAsyncThunk('filters/fetchDeviceIds', async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return await fetchDeviceIds(accessToken);
});

export const getClients = createAsyncThunk('filters/fetchClients', async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return await fetchAllClients(accessToken);
});

export const getLocations = createAsyncThunk('filters/fetchLocations', async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return await fetchAllLocations(accessToken);
});

export const getStatuses = createAsyncThunk('filters/fetchStatuses', async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return await fetchStatuses(accessToken);
});

export const getProducts = createAsyncThunk('filters/fetchProducts', async () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return await fetchLssProducts(accessToken);
});

export const getServiceLevels = createAsyncThunk('filters/fetchServiceLevels', async () => {
    const accessToken = sessionStorage.getItem('shopifyAccessToken');
    return await fetchServiceLevels(accessToken);
});

// Initial state
const initialState = {
    deviceIds: [],
    dateRange: [],
    clients: [],
    locations: [],
    products: [],
    statuses: [],
    serviceLevels: [],
    isLoading: false,
    error: null,
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilter(state, action) {
            const { filterName, value } = action.payload;
            state[filterName] = value;
        },
        setDateRange(state, action) {
            state.dateRange = action.payload;
        },
        getDateRange(state) {
            return state.dateRange;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeviceIds.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDeviceIds.fulfilled, (state, action) => {
                state.isLoading = false;
                state.deviceIds = action.payload;
            })
            .addCase(getDeviceIds.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getClients.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getClients.fulfilled, (state, action) => {
                state.isLoading = false;
                state.clients = action.payload;
            })
            .addCase(getClients.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.message;
            })
            .addCase(getLocations.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLocations.fulfilled, (state, action) => {
                state.isLoading = false;
                state.locations = action.payload;
            })
            .addCase(getLocations.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.message;
            })
            .addCase(getStatuses.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getStatuses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.statuses = action.payload;
            })
            .addCase(getStatuses.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.message;
            })
            .addCase(getServiceLevels.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getServiceLevels.fulfilled, (state, action) => {
                state.isLoading = false;
                state.serviceLevels = action.payload;
            })
            .addCase(getServiceLevels.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.message;
            })
            .addCase(getProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.products = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.message;
            });
    },
});

export const { setFilter, setDateRange, getDateRange } = filtersSlice.actions;
export default filtersSlice.reducer;
