import "./AdminHome.css";
import React, {useEffect} from "react";
import AdminFooter from "../../../components/footers/admin_footer/AdminFooter";
import AdminHeader from "../../../components/headers/admin_header/AdminHeader";
import {useAuth} from "../../../helpers/auth/authHelper";
import {useNavigate} from "react-router-dom";

const AdminHomePage = () => {

    const hostname = window.location.hostname;

    const docTitle = hostname === 'fusion.labb.com' ? "Unauthorized" : "Freddy Vision | Admin Home";

    useEffect(() => {
        document.title = docTitle;
    }, []);

    return (
        <div className="admin-homepage">
            <AdminHeader />
            <main className="dashboard-content">
                {/*<AdminDashboard />*/}
            </main>
            <AdminFooter />
        </div>
    );
};

export default AdminHomePage;
