import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './ClientPricing.css';
import {toggleClientBillingDetailsModal} from '../../../store/clients/clientBillingDetailsSlice';
import {IconButton} from "../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../assets/icons";

const ClientPricingModel = () => {
    const isVisible = useSelector(state => state.clientBillingDetails.isModalVisible);
    const pricingDetails = useSelector(state => state.clientBillingDetails.clientBillingDetailsData);
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");

    const handleClose = () => {
        dispatch(toggleClientBillingDetailsModal(false));
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query === 'bundled' || query === 'unbundled' ? query : query);
    };

    const filteredDetails = pricingDetails?.filter(detail =>
        detail.service_name.toLowerCase().includes(searchQuery) ||
        detail.service_panel_code.toLowerCase().includes(searchQuery) ||
        detail.client_price.toString().toLowerCase().includes(searchQuery) ||
((searchQuery === 'true' || searchQuery === 'unbundled') && detail.unbundled) ||
((searchQuery === 'false' || searchQuery === 'bundled') && !detail.unbundled)
    );

    if (!isVisible) return null;

    return (
        <div className="pricing-modal-overlay">
            <div className="pricing-modal">
                <div className="pricing-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon}
                                onClick={handleClose}/>
                </div>
                <div className="pricing-modal-search">
                    <input
                        type="text"
                        placeholder="Search by service name, code, or price..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className="pricing-modal-search-input"
                    />
                </div>
                <div className="pricing-modal-content">
                    {filteredDetails?.map((detail, index) => (
                        <div key={index} className="pricing-detail">
                            <div>{detail.service_name}</div>
                            <div>{detail.service_panel_code}</div>
                            <div>
                                {detail.unbundled ?  <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.ExitIcon}/> :<IconButton hoverIcon={GenericIcons.CheckIcon} defaultIcon={GenericIcons.CheckIcon}/>
                                }
                            </div>
                            <div>${detail.client_price.toFixed(2)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientPricingModel;
