import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    fetchLaboratoryBillingExport,
    fetchLaboratoryBillingRecords,
    fetchExportStatus,
    fetchLaboratoryRecords,
    fetchStopWatchingSpecimen,
    fetchStartWatchingSpecimen,
    fetchSaveNoteScreen
} from "../../services/api/v1/laboratoryService";

export const startWatchingSpecimen = createAsyncThunk(
    "laboratory/startWatchingSpecimen",
    async (screenId, { dispatch, rejectWithValue }) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            dispatch(updateRecordStatus({ screenId, isWatching: true }));
            const response = await fetchStartWatchingSpecimen(screenId, accessToken);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const stopWatchingSpecimen = createAsyncThunk(
    "laboratory/stopWatchingSpecimen",
    async ({ screenId }, { dispatch, rejectWithValue }) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            dispatch(updateRecordStatus({ screenId, isWatching: false }));
            const response = await fetchStopWatchingSpecimen(screenId, accessToken);
            if (response?.specimen_notes) {
                dispatch(updateSpecimenNotes({ screenId, specimen_notes: response.specimen_notes }));
            } else {
                dispatch(updateSpecimenNotes({ screenId, specimen_notes: [] }));
            }
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const saveNoteScreen = createAsyncThunk(
    "laboratory/saveNoteScreen",
    async ({ screenId, note }, { dispatch, rejectWithValue }) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            const formData = new FormData();
            formData.append("screen_id", screenId);
            formData.append("note", note);
            const response = await fetchSaveNoteScreen(formData, accessToken);
            if (Array.isArray(response)) {
                dispatch(updateScreenDetails({ screenId, specimen_notes: response }));
                return { screenId, specimen_notes: response };
            } else {
                throw new Error("Unexpected response from saveNoteScreen");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const getLaboratoryRecords = createAsyncThunk(
    "laboratory/getLaboratoryRecords",
    async ({ defaultParams }) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("start_date", defaultParams.start_date);
        formData.append("end_date", defaultParams.end_date);
        return await fetchLaboratoryRecords(formData, accessToken);
    }
);

export const getLaboratoryBillingRecords = createAsyncThunk(
    "laboratory/getLaboratoryBillingRecords",
    async (params) => {
        if (params.jobId) {
            return await fetchExportStatus(params.jobId);
        } else {
            const dateRange = params.filters.date_range;
            const accessToken = sessionStorage.getItem("accessToken");
            return await fetchLaboratoryBillingRecords(dateRange, accessToken);
        }
    }
);

export const getLaboratoryBillingExport = createAsyncThunk(
    "laboratory/getLaboratoryBillingExport",
    async (params) => {
        const dateRange = params.filters.date_range;
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchLaboratoryBillingExport(dateRange, accessToken);
    }
);

const initialState = {
    records: [],
    laboratoryRecords: [],
    status: "idle",
    error: null,
    exportStatus: "idle"
};

function isWatching(record) {
    return record.specimen_notes?.some((note) => note.is_watching) || false;
}
function hasUpcomingNotification(record) {
    const now = Date.now();
    return record.specimen_notification?.some((n) => new Date(n.serve_at) > now);
}

function sortRecords(records) {
    return [...records].sort((a, b) => {
        const aWatch = isWatching(a) ? 1 : 0;
        const bWatch = isWatching(b) ? 1 : 0;
        if (bWatch !== aWatch) return bWatch - aWatch;
        const aUpcoming = hasUpcomingNotification(a) ? 1 : 0;
        const bUpcoming = hasUpcomingNotification(b) ? 1 : 0;
        if (bUpcoming !== aUpcoming) return bUpcoming - aUpcoming;
        return new Date(b.collected_on) - new Date(a.collected_on);
    });
}

const laboratorySlice = createSlice({
    name: "laboratory",
    initialState,
    reducers: {
        clearLaboratoryBillingData: (state) => {
            state.records = [];
            state.status = "idle";
            state.error = null;
        },
        updateRecordStatus: (state, action) => {
            const { screenId, isWatching } = action.payload;
            const idx = state.records.findIndex((r) => r.screen_id === parseInt(screenId, 10));
            if (idx !== -1) {
                state.records[idx].is_watching = isWatching;
                state.records = sortRecords(state.records);
            }
        },
        updateSpecimenNotes: (state, action) => {
            const { screenId, specimen_notes } = action.payload;
            const idx = state.records.findIndex((r) => r.screen_id === parseInt(screenId, 10));
            if (idx !== -1) {
                state.records[idx].specimen_notes = specimen_notes;
                state.records = sortRecords(state.records);
            }
        },
        updateScreenDetails: (state, action) => {
            const { screenId, specimen_notes } = action.payload;
            const idx = state.records.findIndex((r) => r.screen_id === parseInt(screenId, 10));
            if (idx !== -1) {
                state.records[idx].specimen_notes = specimen_notes;
                state.records = sortRecords(state.records);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLaboratoryBillingRecords.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getLaboratoryBillingRecords.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.records = sortRecords(action.payload || []);
            })
            .addCase(getLaboratoryBillingRecords.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(getLaboratoryBillingExport.pending, (state) => {
                state.exportStatus = "pending";
            })
            .addCase(getLaboratoryBillingExport.fulfilled, (state) => {
                state.exportStatus = "succeeded";
            })
            .addCase(getLaboratoryBillingExport.rejected, (state, action) => {
                state.exportStatus = "failed";
                state.error = action.error.message;
            })
            .addCase(getLaboratoryRecords.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getLaboratoryRecords.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.records = sortRecords(action.payload || []);
            })
            .addCase(getLaboratoryRecords.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(startWatchingSpecimen.pending, (state) => {
                state.status = "loading";
            })
            .addCase(startWatchingSpecimen.fulfilled, (state, action) => {
                state.status = "succeeded";
                const payload = action.payload || {};
                const screenId = parseInt(Object.keys(payload)[0], 10);
                const updatedNotes = payload[screenId] || [];
                const idx = state.records.findIndex((r) => r.screen_id === screenId);
                if (idx !== -1) {
                    state.records[idx].specimen_notes = updatedNotes;
                }
                state.records = sortRecords(state.records);
            })
            .addCase(startWatchingSpecimen.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload || action.error.message;
            })
            .addCase(stopWatchingSpecimen.pending, (state) => {
                state.status = "loading";
            })
            .addCase(stopWatchingSpecimen.fulfilled, (state) => {
                state.status = "succeeded";
                state.records = sortRecords(state.records);
            })
            .addCase(stopWatchingSpecimen.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload || action.error.message;
            });
    }
});

export const {
    clearLaboratoryBillingData,
    updateRecordStatus,
    updateSpecimenNotes,
    updateScreenDetails
} = laboratorySlice.actions;

export default laboratorySlice.reducer;
