import { makePOSTRequest, makeGETRequest } from "../../utils/httpUtils";

export const fetchLaboratoryBillingRecords = (dateRange, accessToken) => {
    return makePOSTRequest(`/api/v1/export/billing/details`, dateRange, accessToken);
};

export const fetchLaboratoryRecords = (dateRange, accessToken) => {
    return makePOSTRequest(`/api/v1/specimens/details/list`, dateRange, accessToken);
};

export const fetchLaboratoryBillingExport = (dateRange, accessToken) => {
    return makePOSTRequest(`/api/v1/export/billing/csv`, dateRange, accessToken);
};

export const fetchExportStatus = (jobId) => {
    return makeGETRequest(`/api/v1/export/billing/status/${jobId}`);
};

// Start watching a specimen
export const fetchStartWatchingSpecimen = async (screenId, accessToken) => {
    const formData = new FormData();
    formData.append('screen_id', screenId);

    return await makePOSTRequest(`/api/v1/specimens/watch/start`, formData, accessToken);
};

// Stop watching a specimen
export const fetchStopWatchingSpecimen = async (screenId, accessToken) => {
    const formData = new FormData();
    formData.append('screen_id', screenId);

    return await makePOSTRequest(`/api/v1/specimens/watch/stop`, formData, accessToken);
};

// Stop watching a specimen
export const fetchSaveNoteScreen = async (formData, accessToken) => {
    return await makePOSTRequest(`/api/v1/specimens/note/new`, formData, accessToken);
};