import React, { useState, useEffect } from "react";
import './PanelTile.css';
import Masonry from 'react-masonry-css';
import PanelDetailsModel from "../../modals/panel_details/PanelDetails";

const specimenTypeMapping = {
    R: 'Urine',
    O: 'Oral Fluid',
    M: 'Unknown',
    S: 'Unknown',
    C: 'Unknown',
    B: 'Blood',
    A: 'Unknown',
    Q: 'Unknown',
};

const tcTypeColorClass = {
    "Adulterant": 'tc-type-adulterant',
    "Adulterant Required": 'tc-type-adulterant-required',
    "Confirmation Test": 'tc-type-confirmation-test',
    "Remark Test": 'tc-type-remark-test',
    "Other Test": 'tc-type-other-test',
    "Quant Result": 'tc-type-quant-result',
    "Ram Test": 'tc-type-ram-test',
    "Screening Test": 'tc-type-screening-test',
};

const PanelTile = ({ group }) => {
    const [filteredComponents, setFilteredComponents] = useState(group.components);
    const [searchQuery, setSearchQuery] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        const excludedNames = ["Ram Test", "Remark Test", "Other Test"];

        const tcTypeOrder = {
            'Screening Test': 1,
            'Confirmation Test': 2,
            'Quant Result': 3,
            'Adulterant': 4,
            'Adulterant Required': 5,
            'Remark Test': 6,
            'Ram Test': 7,
            'Other Test': 8,
        };

        const filterAndSortComponents = (components) => {
            return components
                .filter(component => !excludedNames.includes(component["TC Type"]))
                .sort((a, b) => (tcTypeOrder[a["TC Type"]] || 9) - (tcTypeOrder[b["TC Type"]] || 9));
        };

        if (searchQuery) {
            setFilteredComponents(filterAndSortComponents(group.components).filter(component =>
                Object.values(component).some(value =>
                    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            ));
        } else {
            setFilteredComponents(filterAndSortComponents(group.components));
        }
    }, [searchQuery, group.components]);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const handleShowDetails = () => {
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setShowDetails(false);
    };

    const breakpointColumnsObj = {
        default: 1
    };

    const upperCase = (string) => {
        return string.toUpperCase();
    };

    const roundedPrice = group.client_price ? group.client_price.toFixed(2) : "N/A"; // Round the client price

    return (
        <div className={`panel-tile-tiles ${expanded ? 'expanded' : ''}`} style={{ width: '100%' }}>
            <div className="panel-header-tiles" onClick={toggleExpanded}>
                <h3>{group.service_description}</h3>
                <p className="panel-code-tiles">{group.panel_code}N</p>
                <p className="client-price-tiles">${roundedPrice}</p>
            </div>
            <div className="panel-detail-tiles" onClick={handleShowDetails}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {filteredComponents.length > 0 ? (
                        filteredComponents.map((component, compIndex) => (
                            <div key={compIndex} className="component-details-panel-tiles">
                                <div className="component-item-panel-tiles">{component["Test Code Name"] || component["Test Code WLName"] || 'N/A'}</div>
                                <div className="component-item-details-tiles">
                                    <div className="component-item-panel-tiles">{component["Screening Cutoff"] || 'N/A'}</div>
                                    <div className="component-item-panel-tiles">{component["Confirmation Cutoff"] || 'N/A'}</div>
                                    <div className="component-item-panel-tiles">{specimenTypeMapping[component["Spec Type"]] || component["Spec Type"]}</div>

                                </div>
                                <div className="component-item-panel-tiles">
                                    <div className={`tc-type-bubble ${tcTypeColorClass[component["TC Type"]]}`}>
                                        {upperCase(component["TC Type"]) || 'N/A'}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="no-matching-panel-tiles">No components match the search criteria.</p>
                    )}
                </Masonry>
            </div>
            {showDetails && <PanelDetailsModel group={group} onClose={handleCloseDetails} tcTypeColorClass={tcTypeColorClass} />}
        </div>
    );
};

export default PanelTile;
