import "./InvoiceFilters.css";
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateRangePicker} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {subDays, subMonths} from "date-fns";
import {allowedMaxDays, afterToday, combine} from "rsuite/cjs/DateRangePicker/disabledDateUtils";

import {
    setDateRange,
} from "../../../store/filters/filtersSlice.js";

function InvoiceFilters({onApplyFilters}) {
    const dispatch = useDispatch();
    const {
        dateRange,
    } = useSelector((state) => state.filters);

    const [searchTerm, setSearchTerm] = useState('');

    const lastWeek = [subDays(new Date(), 6), new Date()];
    const lastMonth = [subMonths(new Date(), 1), new Date()];
    const last3Months = [subMonths(new Date(), 3), new Date()];


    const handleDateRangeChange = (value) => {
        if (!Array.isArray(value) || value.length !== 2) {
            console.error("Invalid date range value:", value);
            return;
        }

        const isoDates = value.map(date => date.toISOString().split('T')[0]);

        let filterValues = {
            start_date: isoDates[0],
            end_date: isoDates[1],
        };

        dispatch(setDateRange(filterValues));

        const filterName = "date_range";
        onApplyFilters(filterName, filterValues);
    };

    const dateRangeValue = Array.isArray(dateRange) ? dateRange.map(dateStr => new Date(dateStr)) : [];


    return (
        <div className="filter-container all-filters">
            <div className="filter-row">
                <div className="filter">
                    <div className="cell">
                        <DateRangePicker
                            showOneCalendar
                            disabledDate={combine(allowedMaxDays(365), afterToday())}
                            appearance="default"
                            placeholder="Select Date Range..."
                            value={dateRangeValue.dateRange}
                            onChange={handleDateRangeChange}
                            ranges={[
                                {label: 'Last Week', value: lastWeek},
                                {label: 'Last Month', value: lastMonth},
                                {label: 'Last 3 Months', value: last3Months},
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceFilters;
