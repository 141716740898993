import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchZohoAuthToken} from "../../services/api/v1/zohoAuthTokenService";

const initialState = {
    zohoAuth: {
        access_token: null,
        refresh_token: null,
        expires_in: null,
        scope: null,
        token_type: null,
        user_id: null,
    },
    isLoading: false,
    error: null,
};

export const getZohoAuth = createAsyncThunk(
    'external/fetchZohoAuthToken',
    async ({zohoGrantToken }, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const tokenData =  await fetchZohoAuthToken({zohoGrantToken, accessToken});
        sessionStorage.setItem("zohoAccessToken", tokenData.access_token);
        sessionStorage.setItem("zohoTokenExpiry", Date.now() + (tokenData.expires_in * 1000));
        return tokenData
    }
);



const zohoAuthSlice = createSlice({
    name: 'zohoAuth',
    initialState,
    reducers: {
        //     store return value from fetchZohoAuthToken
        setZohoAuth: (state, action) => {
            state.zohoAuth = action.payload;
        },
        clearZohoAuth: (state) => {
            state.zohoAuth = null;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [getZohoAuth.fulfilled]: (state, action) => {
            state.zohoAuth = action.payload;
        },
        [getZohoAuth.rejected]: (state, action) => {
            state.zohoAuth = null;
        },
        [getZohoAuth.pending]: (state) => {
            state.isLoading = true;
        },
    }
});



export const {setZohoAuth, clearZohoAuth} = zohoAuthSlice.actions;

export default zohoAuthSlice.reducer;
