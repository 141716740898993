const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${month}/${day}/${year}`;
};

export default formatDate;


export const formatTimestampWithTime = (dateString) => {
    if (!dateString) return "";
    // Ensure the date includes time (set to 00:00:00 if no time exists)
    const date = new Date(dateString);
    if (isNaN(date)) return ""; // If the date is invalid

    // If no time exists, append '00:00:00'
    if (dateString.length === 10) {  // Date only format (YYYY-MM-DD)
        date.setHours(0, 0, 0, 0);  // Set time to 00:00:00
    }

    // Return the formatted date and time
    return date.toISOString().slice(0, 19);  // Returns the format 'YYYY-MM-DDTHH:MM:SS'
};