import React from "react";
import "./HeaderRow.css";

const ClientScreensHeaderRow = () => {
    return (
        <div className="client-screens-header-row">
            <div className="client-screens-header-cell">Specimen ID</div>
            <div className="client-screens-header-cell">Donor</div>
            <div className="client-screens-header-cell">Test Reason</div>
            <div className="client-screens-header-cell">Product</div>
            <div className="client-screens-header-cell">Panel</div>
            <div className="client-screens-header-cell">Collected On</div>
            <div className="client-screens-header-cell">Status</div>
            <div className="client-screens-header-cell">Location</div>
            <div className="client-screens-header-cell">Organization</div>
            <div className="client-screens-header-cell">Drugs</div>
        </div>
    );
};

export default ClientScreensHeaderRow;
