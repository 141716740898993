// src/utils/authUtils.js

import {clearShopifyAuth} from '../store/external/shopifyAuthSlice';
import {useDispatch} from 'react-redux';
import {useEffect} from "react";
import {oauthConfig} from "./oauthConfig";

export const useShopifyLogout = () => {
    const dispatch = useDispatch();
    const hostname = window.location.hostname;

    return () => {
        const idToken = sessionStorage.getItem('shopifyIdToken');
        const logoutUri = `https://shopify.com/66278916260/auth/logout`;
        const redirectUri = `https://${hostname}/`;

        // Redirect to Shopify logout endpoint
        window.location.href = `${logoutUri}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
            redirectUri
        )}`;

        // Clear session storage and Redux state
        sessionStorage.removeItem('shopifyAccessToken');
        sessionStorage.removeItem('shopifyIdToken');
        dispatch(clearShopifyAuth());
    };
};


// Function to exchange the authorization code for tokens
export const exchangeCodeForTokens = async (authorizationCode) => {
    const clientId = 'shp_42bae725-7192-4b16-bc62-42263d70954a';
    const codeVerifier = sessionStorage.getItem('codeVerifier');
    const hostname = window.location.hostname;
    const redirectUri = `https://${hostname}${window.location.pathname}`;

    const body = new URLSearchParams();
    body.append('grant_type', 'authorization_code');
    body.append('client_id', clientId);
    body.append('code', authorizationCode);
    body.append('code_verifier', codeVerifier);
    body.append('redirect_uri', redirectUri);

    const response = await fetch(`https://shopify.com/66278916260/auth/oauth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body.toString(),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to exchange authorization code: ${errorText}`);
    }

    const data = await response.json();
    return { access_token: data.access_token, id_token: data.id_token };
};


// Initiate OAuth for Zoho
export const initiateZohoOAuth = () => {
    const zohoConfig = oauthConfig["zoho"];
    const zohoGrantToken = new URLSearchParams(window.location.search).get("code");
    const zohoAccessToken = sessionStorage.getItem("zohoAccessToken");

    if (!zohoAccessToken && !zohoGrantToken) {
        // Redirect to Zoho's authorization page
        const authURL = `${zohoConfig.authURL}?client_id=${zohoConfig.clientId}&scope=${zohoConfig.scope}&redirect_uri=${zohoConfig.redirectUri}&response_type=code&state=${zohoConfig.state || ""}`;
        window.location.href = authURL;
    }
};

// Initiate OAuth for Zoho
export const initiateUpsOAuth = () => {
    const upsConfig = oauthConfig["ups"];
    const upsGrantToken = new URLSearchParams(window.location.search).get("code");

    console.log("upsGrantToken", upsGrantToken);

    const upsAccessToken = sessionStorage.getItem("upsAccessToken");

    if (!upsAccessToken && !upsGrantToken) {
        // Redirect to Zoho's authorization page
        const authURL = `${upsConfig.authURL}?client_id=${upsConfig.clientId}&scope=${upsConfig.scope}&redirect_uri=${upsConfig.redirectUri}&response_type=code&state=${upsConfig.state || ""}`;
        window.location.href = authURL;
    }
};


// Initiate OAuth for QuickBooks
export const initiateQuickBooksOAuth = () => {
    const quickbooksConfig = oauthConfig["quickbooks"];
    const urlParams = new URLSearchParams(window.location.search);
    const quickbooksGrantToken = urlParams.get("code");

    if (quickbooksGrantToken) {
        sessionStorage.setItem("quickbooksGrantToken", quickbooksGrantToken);
        window.history.replaceState({}, document.title, window.location.pathname); // Clean up URL
    }

    const quickbooksAccessToken = sessionStorage.getItem("quickbooksAccessToken");
    if (!quickbooksAccessToken && !quickbooksGrantToken) {
        const authURL = `${quickbooksConfig.authURL}?client_id=${quickbooksConfig.clientId}&scope=${quickbooksConfig.scope}&redirect_uri=${quickbooksConfig.redirectUri}&response_type=code&state=${quickbooksConfig.state || ""}`;
        window.location.href = authURL;
    }
};

// Initiate OAuth for Shopify Admin
export const initiateShopifyAdminOAuth = () => {
    const shopifyAdminConfig = oauthConfig["shopify"];
    const urlParams = new URLSearchParams(window.location.search);
    const shopifyAdminGrantToken = urlParams.get("code");

    if (shopifyAdminGrantToken) {
        sessionStorage.setItem("shopifyAdminGrantToken", shopifyAdminGrantToken);
        window.history.replaceState({}, document.title, window.location.pathname); // Clean up URL
    }

    const shopifyAdminAccessToken = sessionStorage.getItem("shopifyAdminAccessToken");
    if (!shopifyAdminAccessToken && !shopifyAdminGrantToken) {
        const authURL = `${shopifyAdminConfig.authURL}?client_id=${shopifyAdminConfig.clientId}&scope=${shopifyAdminConfig.scope}&redirect_uri=${shopifyAdminConfig.redirectUri}&response_type=code&state=${shopifyAdminConfig.state || ""}`;
        window.location.href = authURL;
    }
};

