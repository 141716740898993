import React from 'react';
import './PricesRow.css';

const PricesRow = ({ price }) => {
    const specimenTypeColors = {
        'Blood': '#F78E1E',
        'Urine': '#F09583',
        'Oral': '#90AFC5',
        'Collection': '#B0C4DE',
        'Hair': '#FFE4B5',
        'Instant Device': '#ADD8E6',
        'Physicals': '#AFEEEE',
        'Sendout': '#87CEFA',
        'Urine - MPP': '#FFD700',
        'x-KITS': '#E6E6FA',
        'Urine - Non-DOT': '#98FB98',
        'Urine - DOT': '#FA8072',
        'Urine - Steroid': '#BA55D3',
        'Z_Other': '#FFEFD5',
    };

    const markupColors = {
        high: '#3CB371',
        medium: '#F0A830',
        low: '#DD5144',
        veryLow: '#E5E5E5',
    };

    const shouldUseWhiteText = (backgroundColor) => {
        if (typeof backgroundColor !== 'string' || !backgroundColor) return false;

        const color = backgroundColor.replace('#', '');
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);

        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance < 0.5;
    };

    const specimenTypeStyle = {
        backgroundColor: specimenTypeColors[price["Specimen Type"]] || '#6f7170',
        color: shouldUseWhiteText(specimenTypeColors[price["Specimen Type"]]) ? '#FFFFFF' : '#000000',
        padding: '10px',
        borderRadius: '10px',
    };

    const calculateMarkupPercentage = (cost, price) => {
        if (!cost || !price || isNaN(cost) || isNaN(price) || cost <= 0) {
            return null; // Return null for invalid or missing data
        }
        return ((price - cost) / cost) * 100;
    };

    const getMarkupColor = (markupPercentage) => {
        if (markupPercentage === null) return markupColors.veryLow;
        if (markupPercentage >= 50) return markupColors.high;
        if (markupPercentage >= 25) return markupColors.medium;
        if (markupPercentage >= 5) return markupColors.low;
        return markupColors.veryLow;
    };

    // Function to parse numeric values from strings
    const parseCost = (cost) => {
        if (typeof cost === 'string') {
            const parsed = parseFloat(cost.replace(/[$,]/g, '')); // Remove $ and commas, then parse as float
            return isNaN(parsed) ? null : parsed;
        }
        return typeof cost === 'number' ? cost : null;
    };

    const labCost = parseCost(price["Laboratory Cost"]);
    const clientCost = parseCost(price["Client Cost"]);
    const markupPercentage = calculateMarkupPercentage(labCost, clientCost);
    const markupColor = getMarkupColor(markupPercentage);

    return (
        <div className="price-row">
            <div className="price-row-header">
                <h2>{price["Service Name"]}</h2>
                <div className="price-row-service-code">
                    <p>{price["Service Code"]}</p>
                </div>
            </div>
            <div className="price-row-body">
                <div>{price.Laboratory}</div>
                <div style={specimenTypeStyle}>{price["Specimen Type"]}</div>
                <div>{price.Category}</div>
            </div>
            <div className="price-row-costs" style={{ backgroundColor: markupColor }}>
                <div>{price["Laboratory Cost"]}</div>
                <div>{markupPercentage !== null ? `${markupPercentage.toFixed(2)}%` : "N/A"}</div>
                <div>{price["Client Cost"]}</div>
            </div>
        </div>
    );
};

export default PricesRow;

