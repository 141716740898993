// freddy-react/src/components/specimen_tacking/screens/SpecimenTrackingTable.js

import React from 'react';
import './SpecimensTable.css';
import SpecimensRow from "./specimens_row/SpecimensRow";
import HeaderRow from "./header_row/HeaderRow";
import LoadingSpinningCup from "../../../pages/loading/Loading";

const SpecimensTable = ({ userRoles, screens, onEdit }) => {

    console.log(screens)

    return (
        <div className="screens-container">
            <div>
                <HeaderRow />

                <div className="scroll-container">
                {screens?.length > 0
                    ? (
                        screens.map((screenData, index) => (
                            <SpecimensRow
                                key={`${screenData.device_id}-${index}`}
                                userRoles={userRoles}
                                screenData={screenData}
                                onEdit={onEdit}
                            />
                        ))
                    )
                    : (
                        <div>
                                <LoadingSpinningCup />
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    );
};


export default SpecimensTable;
