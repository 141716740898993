import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchClientCollectors} from "../../services/api/v1/screenerService";
import {fetchManageEspQuestUsers} from "../../services/api/v1/questEspUsersService";


export const requestEspCollectorAccess = createAsyncThunk(
    'questEspManagement/requestEspCollectorAccess',
    async ({user, accounts, action}, thunkAPI) => {
        const accessToken = sessionStorage.getItem("shopifyAccessToken");
        const formData = new FormData();

        let manage_esp_users = {
            manage_esp_users: {
                user: user,
                accounts: accounts.map(account => account.value),
                action: action.value
            }
        }

        formData.append('manage_esp_users', JSON.stringify(manage_esp_users));
        await fetchManageEspQuestUsers({formData, accessToken})
        return null
    }
);

export const requestClientCollectors = createAsyncThunk(
    'questEspManagement/requestClientCollectors',
    async ({clientId}, thunkAPI) => {
        const accessToken = sessionStorage.getItem("shopifyAccessToken")
        return await fetchClientCollectors(clientId, accessToken);
    }
);

const questEspManagementSlice = createSlice({
    name: 'questEspManagement',
    initialState: {
        isQuestEspManagementVisible: false,
        lssEspCollectorsDetails: [],
        questEspRequests: [],
        selectedOrganization: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        // Clears the quest orders data
        clearQuestEspManagementData: (state) => {
            state.questEspRequests = [];
            state.lssEspCollectorsDetails = [];
        },
        // Toggles the visibility of the quest order modal
        toggleQuestEspManagementModal: (state) => {
            state.isQuestEspManagementVisible = !state.isQuestEspManagementVisible;
        },
        // Sets the selected organization data for display in the modal
        setCollectorSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestEspCollectorAccess.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestEspCollectorAccess.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.questEspRequests = action.payload || [];
            })
            .addCase(requestEspCollectorAccess.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(requestClientCollectors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestClientCollectors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // console.log("action", action.payload);
                state.lssEspCollectorsDetails = action.payload || [];
            })
            .addCase(requestClientCollectors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the actions to be used in components
export const { toggleQuestEspManagementModal, setCollectorSelectedOrganization, clearQuestEspManagementData } = questEspManagementSlice.actions;

export default questEspManagementSlice.reducer;