import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {getUserRolesFromToken} from "../token/processToken";
import {setUserRoles} from "../../store/user/userSlice";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const initializeAuth = async () => {
            const accessToken = sessionStorage.getItem('accessToken');
            const shopifyAccessToken = sessionStorage.getItem('shopifyAccessToken');

            if (!accessToken && !shopifyAccessToken) {
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }

            try {
                if (accessToken) {
                    const roles = getUserRolesFromToken(accessToken);
                    dispatch(setUserRoles(roles)); // No need to await this Redux action
                    setIsAuthenticated(true);
                } else if (shopifyAccessToken) {
                    setIsAuthenticated(true);
                    // Add Shopify role fetching if needed
                }
            } catch (error) {
                console.error('Authentication initialization error:', error);
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        };

        initializeAuth();
    }, [dispatch]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading }}>
            {isLoading ? <div>Loading...</div> : children}
        </AuthContext.Provider>
    );
};

export const RequireAuth = ({ children, requiredRoles = [] }) => {
    const { isAuthenticated, isLoading } = useAuth();
    const location = useLocation();
    const userRoles = useSelector((state) => state.user.userRoles);

    if (isLoading) {
        return <div>Loading...</div>; // Show a loader while authentication is in progress
    }

    if (!isAuthenticated) {
        sessionStorage.setItem('redirectAfterLogin', location.pathname);
        return <Navigate to="/" replace />;
    }

    // Check if user has all required roles
    if (requiredRoles.length > 0 && !requiredRoles.every((role) => userRoles.includes(role))) {
        console.warn('Missing required roles:', requiredRoles.filter((role) => !userRoles.includes(role)));
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};