import React from 'react';
import './PanelDetails.css';
import { GenericIcons as GenericIcon } from "../../../assets/icons";
import { IconButton } from "../../buttons/icon_button/IconButton";
import Masonry from 'react-masonry-css';

const specimenTypeMapping = {
    R: 'Urine',
    O: 'Oral Fluid',
    M: 'Unknown',
    S: 'Unknown',
    C: 'Unknown',
    B: 'Blood',
    A: 'Unknown',
    Q: 'Unknown',
};

const tcTypeOrder = {
    'Screening Test': 1,
    'Confirmation Test': 2,
    'Quant Result': 3,
    'Adulterant': 4,
    'Adulterant Required': 5,
    'Remark Test': 6,
    'Ram Test': 7,
    'Other Test': 8,
};

const PanelDetailsModel = ({ group, onClose, tcTypeColorClass }) => {

    const excludedNames = ["Ram Test", "Remark Test", "Other Test"];

    const filteredComponents = group.components
        .filter(component => !excludedNames.includes(component["TC Type"]))
        .sort((a, b) => (tcTypeOrder[a["TC Type"]] || 9) - (tcTypeOrder[b["TC Type"]] || 9));

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1
    };

    return (
        <div className="panel-details-model-overlay">
            <div className="panel-details-model-container">
                <div className="panel-details-model-close-container">
                    <IconButton className="panel-details-model-close-button" onClick={onClose} defaultIcon={GenericIcon.CloseIcon} hoverIcon={GenericIcon.ExitIcon} />
                </div>
                <div className="panel-details-model-header">
                    <h3 className="panel-details-model-service-description">{group.service_description}</h3>
                    <p className="panel-details-model-panel-code">{group.panel_code}N</p>
                </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="panel-details-model-masonry-grid"
                    columnClassName="panel-details-model-masonry-grid_column"
                >
                    {filteredComponents.map((component, index) => (
                        <div key={index} className="panel-details-model-component-tiles">
                            <div className="panel-details-model-item-tiles">{component["Test Code Name"] || component["Test Code WLName"] || 'N/A'}</div>
                            <div className="panel-details-model-item-details">
                                <div className="panel-details-model-item-tiles">{component["Screening Cutoff"] || 'N/A'}</div>
                                <div className="panel-details-model-item-tiles">{component["Confirmation Cutoff"] || 'N/A'}</div>
                                <div className="panel-details-model-item-tiles">{specimenTypeMapping[component["Spec Type"]] || component["Spec Type"]}</div>
                            </div>
                            <div className="panel-details-model-item-tiles">
                                <div className={`tc-type-bubble ${tcTypeColorClass[component["TC Type"]]}`}>
                                    {component["TC Type"].toUpperCase() || 'N/A'}
                                </div>
                            </div>
                        </div>
                    ))}
                </Masonry>
            </div>
        </div>
    );
};

export default PanelDetailsModel;
