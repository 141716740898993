import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    fetchAcknowledgeScreenNotification,
    fetchAllNotifications,
    fetchSaveScreenNotification
} from "../../services/api/v1/notificationService";

const initialState = {
    isModalOpen: false,
    notifications: []
};

export const getAllNotifications = createAsyncThunk(
    "screen/getAllNotifications",
    async () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchAllNotifications(accessToken);
    }
);

export const acknowledgeSpecimenNotification = createAsyncThunk(
    "screen/acknowledgeSpecimenNotification",
    async ({ notificationId }) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("notification_id", notificationId);
        return await fetchAcknowledgeScreenNotification(formData, accessToken);
    }
);

export const saveScreenNotification = createAsyncThunk(
    "screen/getAllNotifications",
    async ({ screenId, notificationDateStr }) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("screen_id", screenId);
        formData.append("serve_at", notificationDateStr);
        return await fetchSaveScreenNotification(formData, accessToken);
    }
);

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        toggleModal: (state) => {
            state.isModalOpen = !state.isModalOpen;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllNotifications.fulfilled, (state, action) => {
            state.notifications = action.payload;
        });
    }
});

export const { toggleModal } = notificationsSlice.actions;
export default notificationsSlice.reducer;
