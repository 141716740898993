import React, { useState, useEffect } from 'react';
import './OrderDetailModal.css';
import { useSelector, useDispatch } from 'react-redux';
import { createOrderInvoice, toggleOrderDetailModal } from "../../../store/orders/ordersSlice";
import { GenericIcons } from "../../../assets/icons";
import { IconButton } from "../../buttons/icon_button/IconButton";
import ShipSelection from "./ship_selection/ShipSelection";
import ShipmentRates from "./shipment_rates/ShipmentRates";
import ShipmentBoxDetails from "./shipment_box_details/ShipmentBoxDetails";
import InvoiceSummary from "./invoice_summary/InvoiceSummary";
import CreateInvoice from "./create_invoice/CreateInvoice";

const OrderDetailModal = () => {
    const dispatch = useDispatch();
    const isOrderDetailVisible = useSelector(state => state.orders.isOrderDetailVisible);
    const totalShippingCost = useSelector(state => state.orders.totalShippingCost);
    const selectedOrders = useSelector(state => state.orders.selectedClientOrderDetails);
    const [selectedShipAddress, setSelectedShipAddress] = useState({});
    const [selectedClientName, setSelectedClientName] = useState('');
    const [boxDetails, setBoxDetails] = useState([]);

    let zohoAccessToken = useSelector(state => state.zohoAuth?.zohoAuth?.access_token || '');

    if (zohoAccessToken === '' || zohoAccessToken === null) {
        zohoAccessToken = sessionStorage.getItem("zohoAccessToken");
    }

    useEffect(() => {
        if (selectedOrders) {
            setBoxDetails(selectedOrders.map(orderData => {
                const order = orderData[Object.keys(orderData)[0]];
                return order.box_details;
            }));
        }
    }, [selectedOrders]);

    useEffect(() => {
        if (selectedOrders) {
            setSelectedClientName(selectedOrders.map(orderData => {
                const order = orderData[Object.keys(orderData)[0]];
                return order.ship_location_name;
            }));
        }
    }, [selectedOrders]);

    const handleSetBoxDetails = (index, newBoxDetails) => {
        setBoxDetails(prevState => {
            const updatedBoxDetails = [...prevState];
            updatedBoxDetails[index] = newBoxDetails;
            return updatedBoxDetails;
        });
    };

    const closeModal = () => {
        dispatch(toggleOrderDetailModal());
        setBoxDetails([]);
        setSelectedClientName([]);
        setSelectedShipAddress({});
    };

    if (!isOrderDetailVisible || !selectedOrders || selectedOrders.length === 0) {
        return null;
    }

    const handleSetSelectedShipAddress = (orderId, address) => {
        setSelectedShipAddress(prevState => ({
            ...prevState,
            [orderId]: address
        }));
    };


    const handleCreateInvoice = () => {
        const invoiceDetails = selectedOrders.map((orderData, index) => {
            const order = orderData[Object.keys(orderData)[0]];
            return {
                order_id: order.order_id,
                ship_location_id: order.ship_location_id,
                ship_address: selectedShipAddress[order.order_id],
                box_details: boxDetails[index],
                total_shipping_cost: totalShippingCost,  // pass total shipping cost
            };
        });
        dispatch(createOrderInvoice({ zohoAccessToken, invoiceDetails }))
            .then(() => {
                closeModal();  // After successful invoice creation, close the modal and reset the selected ship addresses and box details.
            });
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <IconButton onClick={closeModal} defaultIcon={GenericIcons.ExitIcon} hoverIcon={GenericIcons.CloseIcon} />
                </div>
                <div className="selected-client-info">
                    <p>{selectedClientName}</p>
                </div>
                <div className="order-detail-section">
                    {selectedOrders?.map((orderData, index) => {
                        const order = orderData[Object.keys(orderData)[0]];
                        return (
                            <div key={order.order_id} className="order-detail-column">
                                <ShipSelection
                                    order={order}
                                    setSelectedShipAddress={(address) => handleSetSelectedShipAddress(order.order_id, address)}
                                />
                                <ShipmentBoxDetails
                                    boxDetails={boxDetails[index] || []}
                                    setBoxDetails={(newBoxDetails) => handleSetBoxDetails(index, newBoxDetails)}
                                />
                                <ShipmentRates
                                    selectedShipAddress={selectedShipAddress[order.order_id]}
                                    productOrderId={order.order_id}
                                    boxesDetailConfiguration={boxDetails[index]}
                                />
                                <InvoiceSummary order={order} />
                                <CreateInvoice handleCreateInvoice={handleCreateInvoice} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default OrderDetailModal;
