import React, { useState, useEffect } from 'react';
import './Orders.css';
import ClientOrders from "../../components/orders/ClientOrders";
import ProductPickList from "../../components/orders/product_pick_list/ProductPickList";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";
import {useDispatch, useSelector} from "react-redux";
import {getUpsAuth} from "../../store/external/upsAuthSlice";
import {initiateUpsOAuth} from "../../utils/authUtils";
import {getZohoAuth} from "../../store/external/zohoAuthSlice";


const OrdersPage = () => {
    const dispatch = useDispatch();
    const [selectedProducts, setSelectedProducts] = useState([]);
    let upsAccessToken = useSelector(state => state.upsAuth.upsAuth?.access_token);

    // Retrieve the access token from session storage if not in state
    if (!upsAccessToken) {
        upsAccessToken = sessionStorage.getItem("upsAccessToken");
    }

    useEffect(() => {
        const upsGrantToken = new URLSearchParams(window.location.search).get("code");

        if (upsGrantToken) {
            // Dispatch to get Zoho Auth token using the grant token from URL
            dispatch(getUpsAuth({ upsGrantToken }));
            sessionStorage.setItem("upsGrantToken", upsGrantToken); // Save for later if needed
            window.history.replaceState({}, document.title, window.location.pathname); // Clean up URL
        } else if (!upsAccessToken) {
            // Initiate OAuth only if accessToken is not already available
            initiateUpsOAuth();
        }
    }, [dispatch, upsAccessToken]);


    const handleProductSelection = (productName) => {
        setSelectedProducts(prevProducts => {
            if (prevProducts.includes(productName)) {
                return prevProducts.filter(product => product !== productName);
            } else {
                return [...prevProducts, productName];
            }
        });
    };

    return (
        <div className="orders-page-container">
            <div className="orders-header-container">
                <AdminHeader />
            </div>
            <div className="orders-body-container">
                <div className="orders-content-container">
                    <div className="orders-table-container">
                        <ProductPickList onSelectProduct={handleProductSelection} selectedProducts={selectedProducts} />
                    </div>
                    <div className="orders-table-container">
                        <ClientOrders selectedProducts={selectedProducts} />
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </div>
    );
};

export default OrdersPage;
