import React, {useEffect, useState, useMemo} from "react";
import "./ClientScreensPage.css";
import {useDispatch, useSelector} from "react-redux";
import {DateRangePicker} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Select from "react-select";
import {subDays, subMonths} from "date-fns";

import {fetchScreensByClientId} from "../../../store/clients/clientScreensSlice";
import {setDateRange} from "../../../store/filters/filtersSlice";
import {useShopifyLogout} from "../../../utils/authUtils";
import ShopifyHeader from "../../../components/headers/shopify_header/ShopifyHeader";
import ClientScreens from "../../../components/clients/screens/ClientScreens";
import LabFusionLogo from "../../../assets/logo/LabFusionLogo.png";
import LabbLogo from "../../../assets/logo/LabbLogo.png";
import {GenericIcons} from "../../../assets/icons";
import {IconButton} from "../../../components/buttons/icon_button/IconButton";

const ClientScreensPage = () => {
    const dispatch = useDispatch();
    const onLogout = useShopifyLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);

    const lssCustomerDetails = useSelector(
        (state) => state.shopifyUser.lssCollectorDetails
    );
    const dateRange = useSelector((state) => state.filters.dateRange);

    const clientScreensData = useSelector((state) => state.clientScreens.clientScreensData);
    const [selectedFilters, setSelectedFilters] = useState({
        testing_reason: null,
        product_name: null,
        panel_name: null,
        status: null,
        location_name: null,
        organization_name: null,
        smallName: null,
    });

    const clientId = lssCustomerDetails?.client_id;

    const hostname = window.location.hostname;
    const logoSrc = hostname === "fusion.labb.com" ? LabFusionLogo : LabbLogo;
    const docTitle = hostname === "fusion.labb.com" ? "Lab Fusion | Manage Screens" : "Labb | Manage Screens";

    const last7Days = [subDays(new Date(), 6), new Date()];

    // Predefined ranges for "Last Month" and "Two Months Ago"
    const rangeOptions = [
        {
            label: "Last 7 Days",
            value: [subDays(new Date(), 6), new Date()],
        },
        {
            label: "Last Month (26th to 25th)",
            value: [
                // Last full billing period: From 26th of the previous month to 25th of the current month
                new Date(new Date().getFullYear(), new Date().getMonth() - 1, 26), // 26th of the previous month
                new Date(new Date().getFullYear(), new Date().getMonth(), 25),     // 25th of the current month
            ],
        },
        {
            label: "Two Months Ago (26th to 25th)",
            value: [
                // Two months ago billing period: From 26th of two months ago to 25th of the last month
                new Date(new Date().getFullYear(), new Date().getMonth() - 2, 26), // 26th of two months ago
                new Date(new Date().getFullYear(), new Date().getMonth() - 1, 25), // 25th of the last month
            ],
        },
    ];


    const filters = useMemo(() => {
        const getUniqueOptions = (key) => [
            ...new Set(clientScreensData?.map((screen) => screen[key]) || []),
        ].map((value) => ({value, label: value}));

        const resultsOptions = [
            ...new Set(
                clientScreensData
                    ?.flatMap((screen) => screen.results || [])
                    ?.map((result) => result.smallName)
                    ?.filter(Boolean)
            ),
        ].map((name) => ({value: name, label: name}));

        return {
            testingReasonOptions: getUniqueOptions("testing_reason"),
            productNameOptions: getUniqueOptions("product_name"),
            panelNameOptions: getUniqueOptions("panel_name"),
            statusOptions: getUniqueOptions("status"),
            locationOptions: getUniqueOptions("location_name"),
            organizationOptions: getUniqueOptions("organization_name"),
            resultsOptions,
        };
    }, [clientScreensData]);

    const handleDateRangeChange = (value) => {
        if (!Array.isArray(value) || value.length !== 2) {
            console.error("Invalid date range value:", value);
            return;
        }

        const isoDates = value.map((date) => date.toISOString().split("T")[0]);
        const newDateRange = { start_date: isoDates[0], end_date: isoDates[1] };

        // Update the date range in the Redux store
        dispatch(setDateRange(newDateRange));

        // Trigger a re-fetch of screen data with the updated date range
        if (clientId) {
            setIsLoading(true); // Show the loading indicator
            dispatch(
                fetchScreensByClientId({
                    selectedClientAccountId: clientId,
                    filters: { date_range: newDateRange },
                })
            ).finally(() => {
                setIsLoading(false); // Hide the loading indicator after fetch
            });
        }
    };

    const handleFilterChange = (key) => (selected) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [key]: selected,
        }));
    };
    const filteredScreens = useMemo(() => {
        let filtered = clientScreensData || [];

        // Apply filters based on selected values
        Object.keys(selectedFilters).forEach((key) => {
            const selected = selectedFilters[key];
            if (selected) {
                filtered = filtered.filter((screen) => {
                    if (key === "smallName") {
                        return (screen.results || []).some(
                            (result) => result.smallName === selected.value
                        );
                    }
                    return screen[key] === selected.value;
                });
            }
        });
        return filtered;
    }, [clientScreensData, selectedFilters]);


    useEffect(() => {
        document.title = docTitle;
    }, [docTitle]);


    // Perform initial fetch and set default filters
    useEffect(() => {
        if (!initialFetchCompleted) {
            // Default date range
            const defaultRange = {
                start_date: last7Days[0].toISOString().split("T")[0],
                end_date: last7Days[1].toISOString().split("T")[0],
            };

            if (!dateRange || dateRange.start_date !== defaultRange.start_date || dateRange.end_date !== defaultRange.end_date) {
                // Set date range in Redux
                dispatch(setDateRange(defaultRange));
            }

            // Fetch initial data if clientId exists
            if (clientId) {
                setIsLoading(true);
                dispatch(
                    fetchScreensByClientId({
                        selectedClientAccountId: clientId,
                        filters: {date_range: defaultRange},
                    })
                )
                    .finally(() => {
                        setIsLoading(false);
                        setInitialFetchCompleted(true); // Ensure no repeated fetch
                    });
            }
        }
    }, [dispatch, clientId, dateRange, initialFetchCompleted, last7Days]);

    const handleDownload = () => {
        const csvRows = [];
        const excludeColumns = ["client_id", "donor_id", "product_id", "location_id", "show_details", "id"];

        // Headers
        const headers = Object.keys(filteredScreens[0] || {}).filter(
            (header) => !excludeColumns.includes(header)
        );
        csvRows.push(headers.join(","));

        // Rows
        filteredScreens.forEach((item) => {
            const values = headers.map((header) => {
                if (header === "results") {
                    return (item.results || [])
                        .filter((result) => result?.lab_check || result?.suspect)
                        .map((result) =>
                            result?.lab_check
                                ? `${result.smallName?.trim() || "Unknown"} (L)`
                                : result.smallName?.trim() || "Unknown"
                        )
                        .join("; ");
                }
                const value = item[header];
                return typeof value === "object" && value !== null
                    ? JSON.stringify(value).replace(/"/g, '""')
                    : value || "";
            });
            csvRows.push(values.join(","));
        });

        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], {type: "text/csv;charset=utf-8;"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${dateRange.start_date}_to_${dateRange.end_date}_screens.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div className="client-screens-page-container">
            <header className="client-screens-header-container">
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout}/>
            </header>
            <main className="client-screens-body-container">
                <section className="client-screens-filter-content-container">
                    <div className="client-screen-filters-container">
                        <Select
                            options={filters.testingReasonOptions}
                            onChange={handleFilterChange("testing_reason")}
                            placeholder="Filter by Testing Reason"
                            isClearable
                        />
                        <Select
                            options={filters.productNameOptions}
                            onChange={handleFilterChange("product_name")}
                            placeholder="Filter by Product"
                            isClearable
                        />
                        <Select
                            options={filters.panelNameOptions}
                            onChange={handleFilterChange("panel_name")}
                            placeholder="Filter by Panel"
                            isClearable
                        />
                        <Select
                            options={filters.statusOptions}
                            onChange={handleFilterChange("status")}
                            placeholder="Filter by Status"
                            isClearable
                        />
                        <Select
                            options={filters.locationOptions}
                            onChange={handleFilterChange("location_name")}
                            placeholder="Filter by Location"
                            isClearable
                        />
                        <Select
                            options={filters.organizationOptions}
                            onChange={handleFilterChange("organization_name")}
                            placeholder="Filter by Organization"
                            isClearable
                        />
                        <Select
                            options={filters.resultsOptions}
                            onChange={handleFilterChange("smallName")}
                            placeholder="Filter by Drug (smallName)"
                            isClearable
                        />
                        <DateRangePicker
                            showOneCalendar
                            shouldDisableDate={(date) => false}
                            appearance="default"
                            placeholder="Select Date Range..."
                            value={
                                dateRange?.start_date && dateRange?.end_date
                                    ? [
                                        new Date(dateRange.start_date),
                                        new Date(dateRange.end_date),
                                    ]
                                    : last7Days
                            }
                            onChange={handleDateRangeChange}
                            ranges={rangeOptions}
                        />
                        <div>
                            <IconButton
                                defaultIcon={GenericIcons.DownloadIcon}
                                hoverIcon={GenericIcons.DownloadIcon}
                                alt="Download"
                                onClick={handleDownload}
                            />
                        </div>
                    </div>
                </section>
                <section className="client-screens-content-container">
                    {isLoading ? (
                        <p>Loading screens...</p>
                    ) : (
                        <ClientScreens clientScreensData={filteredScreens}/>
                    )}
                </section>
            </main>
        </div>
    );
};

export default ClientScreensPage;
