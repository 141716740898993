import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Badganator.css';
import Badganator from "../../baganator/Badganator";
import { toggleBadganatorModal } from "../../../store/badganator/badganatorSlice";
import { GenericIcons } from "../../../assets/icons";
import { IconButton } from "../../buttons/icon_button/IconButton";

const BadganatorModal = () => {
    const isVisible = useSelector(state => state.badganator.isVisible);
    const dispatch = useDispatch();

    if (!isVisible) return null;

    const handleClose = () => {
        dispatch(toggleBadganatorModal());
    };

    return (
        <div className="badganator-modal-overlay">
            <div className="badganator-modal-content">
                <div className="badganator-modal-header">
                    <IconButton
                        onClick={handleClose}
                        defaultIcon={GenericIcons.CloseIcon}
                        hoverIcon={GenericIcons.ExitIcon}
                    />
                </div>
                <div className="badganator-modal-body">
                    <Badganator closeModal={handleClose} />
                </div>
            </div>
        </div>
    );
};

export default BadganatorModal;
