import React from "react";
import "./ClientScreens.css";
import LoadingSpinningCup from "../../../pages/loading/Loading";
import ClientScreensHeaderRow from "./header/HeaderRow";
import formatDate from "../../../utils/dateUtils";

const ClientScreens = ({ clientScreensData }) => {
    if (!clientScreensData) {
        return <LoadingSpinningCup />;
    }

    return (
        <div className="client-screens-container">
            <ClientScreensHeaderRow />
            <div className="client-screens-table">
                {clientScreensData.length > 0 ? (
                    clientScreensData.map((screen, index) => (
                        <div className="client-screens-row" key={`${screen.id}-${index}`}>
                            <div className="client-screens-cell">{screen.device_id}</div>
                            <div className="client-screens-cell">
                                {screen.donor_first_name} {screen.donor_last_name}
                            </div>
                            <div className="client-screens-cell">{screen.testing_reason}</div>
                            <div className="client-screens-cell">{screen.product_name}</div>
                            <div className="client-screens-cell">{screen.panel_name}</div>
                            <div className="client-screens-cell">{formatDate(screen.collected_on)}</div>
                            <div className="client-screens-cell">
                                <span
                                    className={`client-screens-status-badge ${
                                        screen.status === "completed"
                                            ? "status-completed"
                                            : screen.status === "in_progress"
                                                ? "status-in-progress"
                                                : "status-pending"
                                    }`}
                                >
                                    {screen.status}
                                </span>
                            </div>
                            <div className="client-screens-cell">{screen.location_name}</div>
                            <div className="client-screens-cell">{screen.organization_name}</div>
                            <div className="client-screens-cell">
                                {(Array.isArray(screen.results) ? screen.results : [])
                                    .filter((result) => result?.suspect)
                                    .map((result, index) => (
                                        <div key={index} className="client-screens-result-item">
                                            <span>{result?.smallName?.trim() || "N/A"}</span>
                                            {result?.lab_check && (
                                                <span className="client-screens-lab-check-dot"></span>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="client-screens-no-data-message">No screens data available.</p>
                )}
            </div>
        </div>
    );
};

export default ClientScreens;
