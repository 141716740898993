import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import './QuestOrdersModal.css';
import {requestQuestOrder, toggleQuestOrderDetailModal} from "../../../store/orders/questOrderSlice";
import {IconButton} from "../../buttons/icon_button/IconButton";
import SendIcon from "../../../assets/icons/Send.svg";

const QuestOrderModal = () => {
    const dispatch = useDispatch();
    const isQuestOrderDetailsVisible = useSelector(state => state.questOrders.isQuestOrderDetailsVisible);
    const selectedOrganization = useSelector(state => state.questOrders.selectedOrganization);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [panelCode, setPanelCode] = useState(null);

    const addressOptions = [
        {
            value: {line_one: "916 Court Street", line_two: "Unit 200", city: "Norfolk", state: "VA", zip: "23704"},
            label: "916 Court Street, Norfolk, VA, 23704"
        },
        {
            value: {line_one: "123 Main Street", city: "Richmond", state: "VA", zip: "23220"},
            label: "123 Main Street, Richmond, VA, 23220"
        }
    ];

    const panelCodeOptions = [
        {value: "PANEL123", label: "Panel Code 123"},
        {value: "PANEL456", label: "Panel Code 456"}
    ];

    const handleSubmit = () => {
        if (!selectedAddress || !panelCode) {
            alert("Please select both an address and a panel code.");
            return;
        }

        const updatedQuestOrder = {
            items: [
                {
                    item_name: "DOT 5 Part Form",
                    quantity: 1,
                }
            ],
            ship_to_address: {
                attention: "All Start Trucking",
                ...selectedAddress.value, // Dynamically update the selected address
            },
            client_details: {
                organization_id: 12126,
                account_number: "11947042",
                account_name: "All Start Trucking"
            },
            user_login: {
                username: "testuser",
                password: "securepassword"
            },
            panel_code: panelCode.value // Add the selected panel code
        };

        dispatch(requestQuestOrder({questOrder: updatedQuestOrder}));
        alert("Quest order submitted!");
    };

    const handleCloseModal = () => {
        dispatch(toggleQuestOrderDetailModal());
    };

    if (!isQuestOrderDetailsVisible) return null;

    return (
        <div className="quest-order-modal-overlay">
            <div className="quest-order-modal">
                <button className="close-button" onClick={handleCloseModal}>X</button>
                <h2>Quest Order Details</h2>

                <div className="organization-details">
                    <p><strong>Organization Name:</strong> {selectedOrganization?.organization_name || "N/A"}</p>
                    <p><strong>Account Number:</strong> {selectedOrganization?.organization_sub_account_number || "N/A"}
                    </p>
                    <p><strong>Address:</strong> {selectedOrganization?.address || "N/A"}</p>
                </div>

                <div className="address-selection">
                    <label>Select Address:</label>
                    <Select
                        options={addressOptions}
                        value={selectedAddress}
                        onChange={setSelectedAddress}
                        placeholder="Choose an address"
                    />
                </div>

                <div className="panel-code-selection">
                    <label>Select Panel Code:</label>
                    <Select
                        options={panelCodeOptions}
                        value={panelCode}
                        onChange={setPanelCode}
                        placeholder="Choose a panel code"
                    />
                </div>

                <div className="action-buttons">
                    <IconButton
                        defaultIcon={SendIcon}
                        hoverIcon={SendIcon}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default QuestOrderModal;
