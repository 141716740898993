import React, {useEffect, useMemo, useState} from "react";
import "./Laboratory.css";
import {
    getLaboratoryRecords,
    startWatchingSpecimen,
    stopWatchingSpecimen,
    updateRecordStatus,
    updateScreenDetails
} from "../../store/laboratory/laboratorySlice";
import {useDispatch, useSelector} from "react-redux";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";
import {DatePicker, DateRangePicker} from "rsuite";
import {afterToday, allowedMaxDays, combine} from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import LoadingSpinningCup from "../loading/Loading";
import formatDate from "../../utils/dateUtils";
import {GenericIcons} from "../../assets/icons";
import SpecimenNotes from "../../components/modals/notes/specimen_notes/SpceimenNotes";
import {IconButton} from "../../components/buttons/icon_button/IconButton";
import Select from "react-select";
import {acknowledgeSpecimenNotification, saveScreenNotification} from "../../store/notifications/notificationsSlice";
import {showMessageModal} from "../../store/modals/modalsSlice";

const filterNotifications = (notifications = [], type) => {
    const now = Date.now();
    switch (type) {
        case "due":
            return notifications.filter((n) => new Date(n.serve_at).getTime() <= now && !n.acknowledged_at);
        case "upcoming":
            return notifications.filter((n) => new Date(n.serve_at).getTime() > now);
        default:
            return [];
    }
};
const getNotificationIcon = (notifications = []) => {
    const now = Date.now();
    const due = notifications.filter((n) => new Date(n.serve_at).getTime() <= now && !n.acknowledged_at);
    const upcoming = notifications.filter((n) => new Date(n.serve_at).getTime() > now);
    if (due.length) return GenericIcons.BellNotificationIcon;
    if (upcoming.length) return GenericIcons.BellScheduleIcon;
    return GenericIcons.BellIcon;
};
const getNotificationTooltip = (notifications = []) => {
    const due = filterNotifications(notifications, "due");
    const upcoming = filterNotifications(notifications, "upcoming");
    if (due.length) return "Notification Due!";
    if (upcoming.length) {
        const earliest = upcoming.sort((a, b) => new Date(a.serve_at) - new Date(b.serve_at))[0];
        const userTimezoneDate = new Date(earliest.serve_at).toLocaleString("en-US", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        return `Notification Scheduled for ${userTimezoneDate}`;
    }
    return "No notifications scheduled";
};

export const NotificationButton = ({ record, onNotificationClick }) => {
    const notifications = record?.specimen_notification || [];
    const icon = getNotificationIcon(notifications);
    const toolTipText = getNotificationTooltip(notifications);
    return (
        <IconButton
            defaultIcon={icon}
            hoverIcon={icon}
            toolTipText={toolTipText}
            onClick={() => onNotificationClick(record)}
        />
    );
};

const LaboratoryPage = () => {
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState(null);
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [screenParams, setScreenParams] = useState({});
    const [notificationDate, setNotificationDate] = useState(null);
    const [activeNotificationRecord, setActiveNotificationRecord] = useState(null);
    const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);
    const [loadingRows, setLoadingRows] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        product_name: null,
        service_level_description: null,
        organization_name: null,
        location_name: null,
        client_name: null,
        device_id: null
    });
    const laboratoryRecords = useSelector((state) => state.laboratory.records);

    useEffect(() => {
        (async () => {
            const today = new Date();
            const fourteenDaysAgo = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
            const defaultParams = {
                start_date: fourteenDaysAgo.toISOString().split("T")[0],
                end_date: today.toISOString().split("T")[0]
            };
            setScreenParams(defaultParams);
            await dispatch(getLaboratoryRecords({ defaultParams }));
        })();
    }, [dispatch]);

    useEffect(() => {
        setScreenParams((prev) => ({ ...prev }));
    }, [laboratoryRecords]);

    const handleDateRangeChange = async (range) => {
        if (!range) return;
        const [start, end] = range;
        const updatedParams = {
            start_date: start.toISOString().split("T")[0],
            end_date: end.toISOString().split("T")[0]
        };
        setDateRange(range);
        setScreenParams(updatedParams);
        await dispatch(getLaboratoryRecords({ defaultParams: updatedParams }));
    };

    const handleStartWatching = async (screenId, deviceId) => {
        setLoadingRows((prev) => [...prev, screenId]);
        dispatch(updateRecordStatus({ screenId, isWatching: true }));
        try {
            await dispatch(startWatchingSpecimen(screenId)).unwrap();
            dispatch(showMessageModal({ message: `Specimen ${deviceId} is now being watched!` }));
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to start watching specimen." }));
        } finally {
            setLoadingRows((prev) => prev.filter((id) => id !== screenId));
        }
    };

    const handleStopWatching = async (screenId, deviceId) => {
        setLoadingRows((prev) => [...prev, screenId]);
        dispatch(updateRecordStatus({ screenId, isWatching: false }));
        try {
            await dispatch(stopWatchingSpecimen({ screenId })).unwrap();
            dispatch(showMessageModal({ message: `Specimen ${deviceId} is no longer watched.` }));
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to stop watching specimen." }));
        } finally {
            setLoadingRows((prev) => prev.filter((id) => id !== screenId));
        }
    };

    const handleNotificationClick = async (record) => {
        const notifications = record?.specimen_notification || [];
        const now = Date.now();
        const dueNotifications = notifications.filter(
            (n) => new Date(n.serve_at).getTime() <= now && !n.acknowledged_at
        );
        if (dueNotifications.length) {
            try {
                await dispatch(
                    acknowledgeSpecimenNotification({
                        notificationId: dueNotifications[0].specimen_notification_id
                    })
                ).unwrap();
                const updatedRecord = {
                    ...record,
                    specimen_notification: notifications.map((n) =>
                        n.specimen_notification_id === dueNotifications[0].specimen_notification_id
                            ? { ...n, acknowledged_at: new Date().toISOString() }
                            : n
                    )
                };
                dispatch(updateScreenDetails(updatedRecord));
                dispatch(showMessageModal({ message: `Notification for specimen ${record.device_id} acknowledged!` }));
            } catch (error) {
                dispatch(showMessageModal({ message: "Failed to acknowledge notification." }));
            }
        } else {
            setActiveNotificationRecord(record);
        }
    };

    const confirmNotification = async () => {
        if (!notificationDate || !activeNotificationRecord) return;
        try {
            const now = Date.now();
            const selectedDate = new Date(notificationDate).getTime();
            if (selectedDate <= now) return;
            const notificationDateStr = new Date(notificationDate).toISOString();
            await dispatch(
                saveScreenNotification({
                    screenId: activeNotificationRecord.screen_id,
                    notificationDateStr
                })
            ).unwrap();
            setIsNotificationConfirmed(true);
            dispatch(showMessageModal({ message: `Notification set for specimen ${activeNotificationRecord.device_id}!` }));
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to schedule notification." }));
        } finally {
            setActiveNotificationRecord(null);
        }
    };

    const handleNotificationDateChange = (value) => {
        setNotificationDate(value);
    };

    const getIsWatchingStatus = (notes = []) => notes.some((note) => note.is_watching);
    const filterNotes = (notes = []) => notes.filter((note) => !note.is_watching);

    const handleFilterChange = (key) => (selected) => {
        setSelectedFilters((prev) => ({ ...prev, [key]: selected }));
    };

    const filterRecordsBySelectedFilters = (records, filters) => {
        return records.filter((record) =>
            Object.entries(filters).every(([filterKey, selected]) =>
                selected ? record[filterKey] === selected.value : true
            )
        );
    };

    const filteredRecords = useMemo(() => {
        return filterRecordsBySelectedFilters(laboratoryRecords, selectedFilters);
    }, [laboratoryRecords, selectedFilters]);

    const toggleRow = (index) => {
        setExpandedRows((prev) => {
            const updated = new Set(prev);
            updated.has(index) ? updated.delete(index) : updated.add(index);
            return updated;
        });
    };

    return (
        <div className="laboratory-page-container">
            <AdminHeader />
            <div className="laboratory-filter-container">
                <Select
                    options={[...new Set(laboratoryRecords.map((r) => r.product_name || "N/A"))].map(
                        (v) => ({ label: v, value: v })
                    )}
                    onChange={handleFilterChange("product_name")}
                    placeholder="Filter by Product Name"
                    isClearable
                />
                <Select
                    options={[...new Set(laboratoryRecords.map((r) => r.service_level_description || "N/A"))].map(
                        (v) => ({ label: v, value: v })
                    )}
                    onChange={handleFilterChange("service_level_description")}
                    placeholder="Filter by Service Level Description"
                    isClearable
                />
                <Select
                    options={[...new Set(laboratoryRecords.map((r) => r.organization_name || "N/A"))].map(
                        (v) => ({ label: v, value: v })
                    )}
                    onChange={handleFilterChange("organization_name")}
                    placeholder="Filter by Organization Name"
                    isClearable
                />
                <Select
                    options={[...new Set(laboratoryRecords.map((r) => r.location_name || "N/A"))].map(
                        (v) => ({ label: v, value: v })
                    )}
                    onChange={handleFilterChange("location_name")}
                    placeholder="Filter by Location Name"
                    isClearable
                />
                <Select
                    options={[...new Set(laboratoryRecords.map((r) => r.client_name || "N/A"))].map(
                        (v) => ({ label: v, value: v })
                    )}
                    onChange={handleFilterChange("client_name")}
                    placeholder="Filter by Client Name"
                    isClearable
                />
                <Select
                    options={[...new Set(laboratoryRecords.map((r) => r.device_id || "N/A"))].map((v) => ({
                        label: v,
                        value: v
                    }))}
                    onChange={handleFilterChange("device_id")}
                    placeholder="Filter by Device ID"
                    isClearable
                />
                <DateRangePicker
                    showOneCalendar
                    shouldDisableDate={combine(allowedMaxDays(365), afterToday())}
                    appearance="default"
                    placeholder="Select Date Range..."
                    value={dateRange}
                    onChange={handleDateRangeChange}
                />
            </div>
            <div className="laboratory-content-container">
                <div className="laboratory-records-table">
                    <div className="laboratory-table-header">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div>Device ID</div>
                        <div>Service Level</div>
                        <div>Client Name</div>
                        <div>Collected Date</div>
                    </div>
                    <div className="laboratory-table-body">
                        {filteredRecords.map((record, index) => (
                            <div
                                key={record.screen_id}
                                className={`laboratory-table-row ${
                                    expandedRows.has(index) ? "expanded" : ""
                                }`}
                            >
                                <div>
                                    <IconButton
                                        defaultIcon={
                                            expandedRows.has(index)
                                                ? GenericIcons.ContractIcon
                                                : GenericIcons.ExpandIcon
                                        }
                                        hoverIcon={
                                            expandedRows.has(index)
                                                ? GenericIcons.ContractIcon
                                                : GenericIcons.ExpandIcon
                                        }
                                        onClick={() => toggleRow(index)}
                                    />
                                </div>
                                <div>
                                    <NotificationButton record={record} onNotificationClick={handleNotificationClick}/>
                                    {activeNotificationRecord?.screen_id === record.screen_id && (
                                        <div className="notification-set-container">
                                            <DatePicker
                                                appearance="default"
                                                placeholder="Select Notification Date"
                                                value={notificationDate}
                                                onChange={handleNotificationDateChange}
                                                disabledDate={(date) => date <= new Date()}
                                                ranges={[
                                                    {
                                                        label: "Tomorrow",
                                                        value: new Date(Date.now() + 24 * 60 * 60 * 1000)
                                                    },
                                                    {
                                                        label: "In 2 Days",
                                                        value: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
                                                    },
                                                    {
                                                        label: "In 3 Days",
                                                        value: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)
                                                    }
                                                ]}
                                            />
                                            <IconButton
                                                defaultIcon={GenericIcons.CheckIcon}
                                                hoverIcon={GenericIcons.CheckIcon}
                                                disabled={!notificationDate || isNotificationConfirmed}
                                                onClick={confirmNotification}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="laboratory-actions-container">
                                    <IconButton
                                        defaultIcon={
                                            loadingRows.includes(record.screen_id) ? (
                                                <LoadingSpinningCup size="small"/>
                                            ) : getIsWatchingStatus(record.specimen_notes || []) ? (
                                                GenericIcons.WatchEyeIcon
                                            ) : (
                                                GenericIcons.EyeIcon
                                            )
                                        }
                                        hoverIcon={
                                            getIsWatchingStatus(record.specimen_notes || [])
                                                ? GenericIcons.WatchEyeIcon
                                                : GenericIcons.EyeIcon
                                        }
                                        onClick={() =>
                                            getIsWatchingStatus(record.specimen_notes || [])
                                                ? handleStopWatching(record.screen_id, record.device_id)
                                                : handleStartWatching(record.screen_id, record.device_id)
                                        }
                                    />
                                </div>
                                <div>
                                    {record.admin_portal_url ? (
                                        <a href={record.admin_portal_url} target="_blank" rel="noopener noreferrer">
                                            <IconButton defaultIcon={GenericIcons.SendIcon}
                                                        hoverIcon={GenericIcons.SendIcon}/>
                                        </a>
                                    ) : "N/A"}
                                </div>
                                <div>
                                    <SpecimenNotes
                                        key={record.screen_id}
                                        screenDetails={record}
                                        notes={filterNotes(record.specimen_notes || [])}
                                    />
                                </div>
                                <div>{record.device_id || "N/A"}</div>
                                <div>{record.service_level_description || "N/A"}</div>
                                <div>{record.client_name || "N/A"}</div>
                                <div>{formatDate(record.collected_on) || "N/A"}</div>
                                <div>{record.screen_status_str || "N/A"}</div>
                                {expandedRows.has(index) && (
                                    <div className="laboratory-expanded-content">
                                        <div>{record.location_name || "N/A"}</div>
                                        <div>{record.organization_name || "N/A"}</div>
                                        <div>{record.product_name || "N/A"}</div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LaboratoryPage;
