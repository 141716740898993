
import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './QuestAccountRequestModal.css';
import { IconButton } from '../../buttons/icon_button/IconButton';
import { PrimaryButton } from '../../buttons/primary_button/PrimaryButton';
import { GenericIcons } from '../../../assets/icons';
import {
    getAccountCreationRequest,
    toggleQuestAccountManagementModal
} from "../../../store/laboratory/questAccountRequestSlice";

const QuestAccountRequestModal = () => {
    const dispatch = useDispatch();
    const isVisible = useSelector(state => state.questAccountRequests.isAccountCreationRequestModalVisible);
    const selectedOrganization = useSelector(state => state.questAccountRequests.selectedOrganization);
    const status = useSelector(state => state.questAccountRequests.status);
    const [requestDotAccount, setRequestDotAccount] = useState(false);
    const [requestNonDotAccount, setRequestNonDotAccount] = useState(false);

    const handleClose = () => {
        dispatch(toggleQuestAccountManagementModal());
    };

    const handleApproveRequest = () => {
        dispatch(getAccountCreationRequest({ organizationId: selectedOrganization.organization_id }));
    };

    if (!isVisible) return null;

    const hasDotAccount = !!selectedOrganization?.organization_dot_sub_account_number;
    const hasNonDotAccount = !!selectedOrganization?.organization_sub_account_number;

    const handleToggleDotAccount = () => {
        if (!hasDotAccount) setRequestDotAccount(!requestDotAccount);
    };

    const handleToggleNonDotAccount = () => {
        if (!hasNonDotAccount) setRequestNonDotAccount(!requestNonDotAccount);
    };

    return (
        <div className="account-request-modal-overlay">
            <div className="account-request-modal">
                <div className="account-request-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon}
                                onClick={handleClose}/>
                </div>
                <h2 className="account-request-modal-title">Review Account Creation Request</h2>

                <div className="account-details-section">
                    <h3>Organization Details</h3>
                    <div><strong>Name:</strong> {selectedOrganization.organization_name}</div>
                    <div><strong>Account Number:</strong> {selectedOrganization.organization_sub_account_number}</div>
                    <div><strong>DOT Account
                        Number:</strong> {selectedOrganization.organization_dot_sub_account_number || 'N/A'}</div>
                    <div><strong>Contact:</strong> {selectedOrganization.der_contact}</div>
                    <div><strong>Phone:</strong> {selectedOrganization.der_telephone}</div>
                    <div>
                        <strong>Address:</strong> {selectedOrganization.address}, {selectedOrganization.city}, {selectedOrganization.state} {selectedOrganization.zip}
                    </div>
                </div>
                <div className="modal-section account-type-toggle">
                    <label>Request Account Type:</label>
                    <div className="account-toggle-options">
                        <label>
                            <input
                                type="checkbox"
                                checked={requestDotAccount}
                                onChange={handleToggleDotAccount}
                                disabled={hasDotAccount}
                            />
                            DOT Account
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={requestNonDotAccount}
                                onChange={handleToggleNonDotAccount}
                                disabled={hasNonDotAccount}
                            />
                            Non-DOT Account
                        </label>
                    </div>
                </div>
                <div className="action-buttons">
                    <PrimaryButton onClick={handleApproveRequest} disabled={status === 'loading'}>
                        {status === 'loading' ? 'Submitting...' : 'Approve Request'}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default QuestAccountRequestModal;
