// freddy-react/src/components/clients/ClientsTable.js
import React, {useState} from "react";
import "./ClientsTable.css";
import ClientsRow from "./clients_row/ClientsRow";
import {PrimaryButton} from "../buttons/primary_button/PrimaryButton";
import {IconButton} from "../buttons/icon_button/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {
    clearLaboratoryAccountData,
    getLabbMlAccountRecords,
    getLaboratoryAccountRecords,
} from "../../store/laboratory/laboratoryAccountSlice";
import {LaboratoryAccountsIcons} from "../../assets/icons";
import {showMessageModal} from "../../store/modals/modalsSlice";

const ClientsTable = ({clients, userRoles}) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.laboratorySlice.status === "loading");
    const csvUrl = useSelector((state) => state.laboratorySlice.csvUrl || "");

    // One free-typing search field for all columns
    const [globalSearchTerm, setGlobalSearchTerm] = useState("");

    // "Link Accounts" button handler
    const linkQuestAccounts = () => {
        dispatch(getLabbMlAccountRecords());
    };

    // "Download" button handler
    const handleDownloadButtonClick = () => {
        dispatch(getLaboratoryAccountRecords());
        dispatch(showMessageModal({message: "Started Download", link: csvUrl}));
        dispatch(clearLaboratoryAccountData());
    };

    // Filter clients by checking if the search term is found in any of the key fields
    const filteredClients = clients
        .filter((client, index, self) => self.findIndex(c => c.id === client.id) === index) // Remove duplicates based on client ID
        .filter((client) => {
            const searchContent = (
                client.company +
                " " +
                client.address +
                " " +
                (client.rapid_service_level === "1"
                    ? "Rapid Only"
                    : client.rapid_service_level === "2"
                        ? "Rapid + Tox"
                        : client.rapid_service_level === "3"
                            ? "Rapid + Tox + MRO (POS ONLY)"
                            : "") +
                " " +
                (client.rapid_lab === "quest_diagnostics" ? "Quest" : client.rapid_lab || "") +
                " " +
                (client.rapid_mro_name || "") +
                " " +
                (client.tox_service_level === "1"
                    ? "Tox"
                    : client.tox_service_level === "2"
                        ? "Tox + MRO (POS ONLY)"
                        : client.tox_service_level === "3"
                            ? "Tox + MRO (ALL)"
                            : "") +
                " " +
                (client.tox_lab === "quest_diagnostics" ? "Quest" : client.tox_lab || "") +
                " " +
                (client.tox_mro_name || "") +
                " " +
                (client.dot_service ? "Yes" : "No") +
                " " +
                (client.paper_chain_service ? "Yes" : "No") +
                " " +
                (client.organization_string || "") +
                " " +
                (client.positive_string || "")
            ).toLowerCase();
            return searchContent.includes(globalSearchTerm.toLowerCase());
        });

    return (
        <div className="client-table-container">
            <div className="client-header">
                <div className="client-search-container">
                    <input
                        type="text"
                        className="client-search-input"
                        placeholder="Search clients..."
                        value={globalSearchTerm}
                        onChange={(e) => setGlobalSearchTerm(e.target.value)}
                    />
                </div>
                <div className="client-button-container">
                    <PrimaryButton type="button" onClick={linkQuestAccounts} disabled={loading}>
                        {loading ? "LOADING..." : "LINK ACCOUNTS"}
                    </PrimaryButton>
                    <IconButton
                        className="client-download-button"
                        defaultIcon={LaboratoryAccountsIcons.DownloadIcon}
                        hoverIcon={LaboratoryAccountsIcons.DownloadIcon}
                        onClick={handleDownloadButtonClick}
                    />
                </div>
            </div>
            <div className="client-table-content">
                <div className="client-table">
                    <div className="client-table-header">
                        <div className="client-col-client">Client</div>
                        <div className="client-col-rapid">Rapid Details</div>
                        <div className="client-col-tox">Tox Details</div>
                        <div className="client-col-dot">DOT Service</div>
                        <div className="client-col-paper">Paper Chain</div>
                        <div className="client-col-org">Organization</div>
                        <div className="client-col-positive">Positive</div>
                        <div className="client-col-actions">Actions</div>
                    </div>
                    <div className="client-table-body">
                        {filteredClients.map((client, index) => (
                            <ClientsRow key={`${client.id}-${index}`} client={client} userRoles={userRoles}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientsTable;
