// freddy-react/src/pages/screens/Screens.js
import React, { useEffect, useState } from "react";
import "./Clients.css";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";
import { useDispatch, useSelector } from "react-redux";
import ClientsTable from "../../components/clients/ClientsTable";
import { getAllClientDetails } from "../../store/clients/clientsSlice";
import LoadingSpinningCup from "../loading/Loading";

const ClientsPage = ({ userRoles }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.clients.isLoading);
    const clientsData = useSelector((state) => state.clients.clientData) || [];
    const [clientParams] = useState({ offset: 0, limit: 1000, filters: {} });

    useEffect(() => {
        dispatch(getAllClientDetails(clientParams));
    }, [dispatch, clientParams]);

    return (
        <div className="clients-page-container">
            <div className="clients-header-container">
                <AdminHeader />
            </div>
            <div className="clients-body-container">
                <div className="clients-content-container">
                    {isLoading ? (
                        <LoadingSpinningCup />
                    ) : (
                        <ClientsTable clients={clientsData} userRoles={userRoles} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClientsPage;
