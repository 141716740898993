
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ClientSpecimenNotes.css";
import {closeSpecimenNotesModal, getSpecimenNotes} from "../../../store/screens/screenNotesSlice";
import {IconButton} from "../../buttons/icon_button/IconButton";
import NoteList from "../../modals/notes/note_list/NoteList";
import {GenericIcons} from "../../../assets/icons";

export default function ClientSpecimenNotes() {
    const dispatch = useDispatch();
    const { notes, status, error } = useSelector((state) => state.notes);
    const { isScreenNotesModalOpen } = useSelector((state) => state.notes);


    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
    const dateRange = [thirtyDaysAgo, new Date()];

    const onClose = () => {
        dispatch(closeSpecimenNotesModal())
    };

    // Fetch notes once when the component mounts, if not already loaded or loading
    useEffect(() => {
        if (status === "idle") {
            const [startDate, endDate] = dateRange;
            dispatch(getSpecimenNotes({ startDate, endDate }));
        }
    }, [dispatch, dateRange, status]);

    if (!notes || !isScreenNotesModalOpen) return null;

    return (
        <div className="specimen-notes-modal">
            <div className="modal-header">
                <h2>Specimen Notes</h2>
                <IconButton
                    defaultIcon={GenericIcons.CloseIcon}
                    hoverIcon={GenericIcons.ExitIcon}
                    onClick={onClose}
                    className="close-button"
                />
            </div>

            <div className="modal-content">
                {status === "loading" ? (
                    <p>Loading notes...</p>
                ) : error ? (
                    <p className="error">Error: {error}</p>
                ) : notes.length === 0 ? (
                    <p>No notes available</p>
                ) : (
                    notes.map((note, index) => (
                        <div key={index} className="specimen-note">
                            <NoteList note={note} />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
