import "./ShopifyHeader.css";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HeaderIcons } from "../../../assets/icons";
import { getShopifyOAuthUrl } from "../../user/sign_in/SignIn";
import UserIconCard from "../../user/icon_card/IconCard";
import {useSelector} from "react-redux";

const ShopifyHeader = ({ logoSrc, onLogout }) => {

    let shopifyAccessToken = useSelector((state) => state.shopifyAuth.shopifyAuth);
    const lssCustomerDetails = useSelector((state) => state.shopifyUser.lssCollectorDetails);

    if (!shopifyAccessToken) {
        shopifyAccessToken = sessionStorage.getItem("shopifyAccessToken");
    }

    // Function to initiate Shopify OAuth flow
    const handleCollectorLogin = async () => {
        const hostname = window.location.hostname;
        try {
            window.location.href = await getShopifyOAuthUrl(hostname);
        } catch (error) {
            console.error("Failed to initiate Shopify OAuth flow:", error);
        }
    };

    return (
        <header className="shopify-header">
            <div className="header-left">
                <NavLink to="/shopify/home" className="logo-link">
                    <img className="header-logo" src={logoSrc} alt="Logo" />
                </NavLink>
            </div>
            <div className="header-right">
                {shopifyAccessToken ? (
                    <nav className="navbar-container">
                        {lssCustomerDetails?.can_manage_panels ? (
                            <div className="nav-item">
                                <NavLink to="/panels">
                                    <img
                                        src={HeaderIcons.PanelsIcon}
                                        alt="Panels"
                                        className="nav-icon"
                                    />
                                </NavLink>
                            </div>
                        ) : null}

                        {/*{lssCustomerDetails?.can_order_cartons ? (*/}
                        {/*    <div className="nav-item">*/}
                        {/*        <NavLink to="/orders">*/}
                        {/*            <img*/}
                        {/*                src={HeaderIcons.BillingInvoicesIcon}*/}
                        {/*                alt="Billing Invoices"*/}
                        {/*                className="nav-icon"*/}
                        {/*            />*/}
                        {/*        </NavLink>*/}
                        {/*    </div>*/}
                        {/*) : null}*/}

                        {lssCustomerDetails?.can_manage_screeners ? (
                            <div className="nav-item">
                                <NavLink to="/organizations">
                                    <img
                                        src={HeaderIcons.PersonIcon}
                                        alt="Billing Invoices"
                                        className="nav-icon"
                                    />
                                </NavLink>
                            </div>
                        ) : null}

                        {lssCustomerDetails?.can_manage_results ? (
                            <div className="nav-item">
                                <NavLink to="/specimens">
                                    <img
                                        src={HeaderIcons.SpeicmenRapidCupIcon}
                                        alt="Specimen Audits"
                                        className="nav-icon"
                                    />
                                </NavLink>
                            </div>
                        ) : null}
                    </nav>
                ) : (
                    <div>Login required</div>
                )}
                <div className="user-icon-card">
                    <UserIconCard
                        handleCollectorLogin={handleCollectorLogin}
                        onLogout={onLogout}
                    />
                </div>
            </div>
        </header>
    );
};

export default ShopifyHeader;
