import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    fetchClientBillingDetails,
    createClientSpecimenInvoices,
    fetchInvoiceBillingDetails,
    fetchExistingClientInvoice,
    checkJobStatus, createClientQBSpecimenInvoices // New function to check job status
} from "../../services/api/v1/clientBillingService";
import {useSelector} from "react-redux";

const initialState = {
    invoicesBillingDetails: null,
    clientBillingDetailsData: null,
    existingClientInvoices: [],
    totalOverallInvoiceLabbCost: 0,
    totalOverallInvoiceRetailPrice: 0,
    isModalVisible: false,
    loading: false,
    error: null,
    jobStatus: null, // New state to store job status
    jobId: null, // New state to store job ID
    polling: false, // New state to control polling
};

export const getInvoiceBillingDetails = createAsyncThunk(
    'clientBillingDetails/getInvoiceBillingDetails',
    async (params, thunkAPI) => {
        const dateRange = params.filters.date_range;
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchInvoiceBillingDetails(dateRange, accessToken);
    }
);

export const getClientBillingDetails = createAsyncThunk(
    'clientBillingDetails/getClientBillingDetails',
    async ({clientId}, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchClientBillingDetails(clientId, accessToken);
    }
);

export const fetchExistingClientInvoices = createAsyncThunk(
    'clientBillingDetails/fetchExistingClientInvoices',
    async (params, thunkAPI) => {
        const state = thunkAPI.getState();
        const dateRange = params.filters.date_range;
        const accessToken = sessionStorage.getItem("accessToken");
        const zohoAccessToken = sessionStorage.getItem("zohoAccessToken");
        const formData = new FormData();
        formData.append('start_date', dateRange.start_date);
        formData.append('end_date', dateRange.end_date);
        formData.append('zoho_oauth_token', zohoAccessToken);
        const response = await fetchExistingClientInvoice(formData, accessToken);

        // Start polling if the job is queued
        if (response.job_id) {
            thunkAPI.dispatch(startPolling(response.job_id));
        }
        return response;
    }
);

export const createBillingSpecimenInvoices = createAsyncThunk(
    'clientBillingDetails/createBillingSpecimenInvoices',
    async ({zohoAccessToken}, thunkAPI) => {
        const state = thunkAPI.getState();
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append('start_date', state.filters.dateRange.start_date);
        formData.append('end_date', state.filters.dateRange.end_date);
        formData.append('zoho_oauth_token', zohoAccessToken);
        return await createClientSpecimenInvoices(formData, accessToken);
    }
);

export const createQuickbooksBillingSpecimenInvoices = createAsyncThunk(
    'clientBillingDetails/createQuickbooksBillingSpecimenInvoices',
    async ({quickbooksAccessToken}, thunkAPI) => {
        const state = thunkAPI.getState();
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append('start_date', state.filters.dateRange.start_date);
        formData.append('end_date', state.filters.dateRange.end_date);
        formData.append('quickbooks_oauth_token', quickbooksAccessToken);
        return await createClientQBSpecimenInvoices(formData, accessToken);
    }
);

// New async thunk to check job status
export const checkJobStatusAsync = createAsyncThunk(
    'clientBillingDetails/checkJobStatus',
    async (jobId, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await checkJobStatus(jobId, accessToken);
    }
);

const clientBillingDetailsSlice = createSlice({
    name: 'clientBillingDetails',
    initialState,
    reducers: {
        toggleClientBillingDetailsModal: (state, action) => {
            state.isModalVisible = typeof action.payload === 'boolean' ? action.payload : !state.isModalVisible;
        },
        startPolling: (state, action) => {
            state.polling = true;
            state.jobId = action.payload;
        },
        stopPolling: (state) => {
            state.polling = false;
            state.jobId = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getClientBillingDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getClientBillingDetails.fulfilled, (state, action) => {
                state.clientBillingDetailsData = action.payload;
                state.loading = false;
                state.isModalVisible = true;
            })
            .addCase(getInvoiceBillingDetails.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(getInvoiceBillingDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getInvoiceBillingDetails.fulfilled, (state, action) => {
                state.invoicesBillingDetails = action.payload;
                state.loading = false;
            })
            .addCase(fetchExistingClientInvoices.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(fetchExistingClientInvoices.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchExistingClientInvoices.fulfilled, (state, action) => {
                state.existingClientInvoices = action.payload.result?.merged_invoices || [];
                state.totalOverallInvoiceLabbCost = action.payload.result?.total_labb_cost || 0;
                state.totalOverallInvoiceRetailPrice = action.payload.result?.total_client_total || 0;
                state.loading = false;
                if (action.payload.job_id) {
                    state.jobId = action.payload.job_id;
                    state.polling = true;
                }
            })
            .addCase(getClientBillingDetails.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(createBillingSpecimenInvoices.fulfilled, (state, action) => {
                state.clientBillingDetailsData = action.payload;
            })
            .addCase(checkJobStatusAsync.fulfilled, (state, action) => {
                state.jobStatus = action.payload.status;
                if (action.payload.status === 'completed' || action.payload.status === 'failed') {
                    state.polling = false;
                    state.existingClientInvoices = action.payload.result?.merged_invoices || [];
                    state.totalOverallInvoiceLabbCost = action.payload.result?.total_labb_cost || 0;
                    state.totalOverallInvoiceRetailPrice = action.payload.result?.total_client_total || 0;
                }
            })
            .addCase(checkJobStatusAsync.rejected, (state, action) => {
                state.error = action.error.message;
                state.polling = false;
            });
    },
});

export const { toggleClientBillingDetailsModal, startPolling, stopPolling } = clientBillingDetailsSlice.actions;
export default clientBillingDetailsSlice.reducer;
