import React, { useEffect, useState, useMemo } from 'react';
import './OrganizationsTable.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchShopifyOrganizationsByClientId
} from "../../../../store/autobot/clients/organizations/organizationsSlice";
import LoadingSpinningCup from "../../../../pages/loading/Loading";
import HeaderRow from "../header_row/HeaderRow";
import OrganizationRow from "../organizations_row/OrganizationsRow";

const OrganizationsTable = ({ clientId, userRoles }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search input

    const organizationsData = useSelector(state => state.organizations.organizationData) || [];

    useEffect(() => {
        if (clientId) { // Check if clientId is present
            const fetchData = async () => {
                setIsLoading(true);
                await dispatch(fetchShopifyOrganizationsByClientId({ selectedClientAccountId: clientId }));
                setIsLoading(false);
            };

            fetchData();
        }
    }, [dispatch, clientId]);



    // Memoized filtered organizations based on the search term
    const filteredOrganizations = useMemo(() => {
        // If searchTerm is empty, return all organizations
        if (!searchTerm) {
            return organizationsData;
        }

        // Apply filtering logic
        return organizationsData.filter((organization) => {
            const name = organization.organization_name?.toLowerCase() || ""; // Safely access name
            const organization_non_dot_account_number = organization.organization_non_dot_account_number?.toString() || ""; // Safely access id
            const organization_sub_account_number = organization.organization_sub_account_number?.toString() || ""; // Safely access id
             const organization_dot_sub_account_number = organization.organization_dot_sub_account_number?.toString() || ""; // Safely access id
            const der_contact = organization.der_contact?.toString() || ""; // Safely access id
            const der_telephone = organization.der_telephone?.toString() || ""; // Safely access id
            const address = organization.address?.toString() || ""; // Safely access id
            return name.includes(searchTerm.toLowerCase()) || organization_non_dot_account_number.includes(searchTerm)  || organization_dot_sub_account_number.includes(searchTerm) || organization_sub_account_number.includes(searchTerm) || der_contact.includes(searchTerm) || der_telephone.includes(searchTerm) || address.includes(searchTerm);
        });
    }, [organizationsData, searchTerm]);

    return (
        <div className="organization-table-container">
            {/* Search Bar */}
            <div className="organization-filter-container">
                <input
                    type="text"
                    placeholder="Search organizations..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="organization-filter-input"
                />
            </div>
            {/* Display filtered data */}
            {isLoading ? (
                <LoadingSpinningCup/>
            ) : (
                filteredOrganizations.length > 0 ? (
                    <div>
                        <HeaderRow/>
                        <div className="organization-table-scroll-container">
                            {filteredOrganizations.map((organization) => (
                                <OrganizationRow
                                    key={`${organization.organization_id}-${clientId}`}
                                    organization={organization}
                                    userRoles={userRoles}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <p className="no-data-message">No organizations found.</p>
                )
            )}
        </div>
    );
};

export default OrganizationsTable;
