// freddy-react/src/components/specimen_tacking/screens/SpecimenTrackingTable.js

import React, {useEffect, useState} from 'react';
import './Organizations.css';
import HeaderRow from "./header_row/HeaderRow";
import OrganizationRow from "./organizations_row/OrganizationsRow";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchOrganizationsByClientId,
    fetchShopifyOrganizationsByClientId
} from "../../../store/autobot/clients/organizations/organizationsSlice";
import LoadingSpinningCup from "../../../pages/loading/Loading";

const OrganizationsTable = ({clientId, onEdit, onScroll}) => {
    const dispatch = useDispatch();
    // console.log("clientId", clientId);

    const [isLoading, setIsLoading] = useState(false);
    // Fetching clients data from Redux state
    const organizationsData = useSelector(state => state.organizations.allOrganizationData);
    const organizations = organizationsData ? organizationsData : [];

    useEffect(() => {
        if (clientId) { // Check if clientId is present
            const fetchData = async () => {
                setIsLoading(true);
                await dispatch(fetchOrganizationsByClientId({ selectedClientAccountId: clientId }));
                setIsLoading(false);
            };

            fetchData();
        }
    }, [dispatch, clientId]);



    return (
        <div className="clients-container" onScroll={onScroll}>
            {
            organizationsData ?
            <table>
                <HeaderRow/>
                <tbody className="scroll-container">
                {organizations.map((organization) => (
                    <OrganizationRow key={`${organization.organization_id}-${clientId}`} organization={organization} onEdit={onEdit}/>
                ))}
                </tbody>
            </table>
                : <LoadingSpinningCup />
            }
        </div>
    );
};


export default OrganizationsTable;
