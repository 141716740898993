/**
 * @file AppRoutes.js
 * @description This file represents the main routing configuration for the application.
 * It manages authentication flows and determines the routes that are available to a user
 * based on their roles.
 */

import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ScreenPage from "../pages/screen/Screen";
import LaboratoryPage from "../pages/lab_tracking/Laboratory";
import MedicalReviewOfficerPage from "../pages/medical_review_officer/MedicalReviewOfficerPage";
import OrdersPage from "../pages/orders/Orders";
import BadganatorPage from "../components/modals/baganator/Badganator";
import InvoicesPage from "../pages/invoices/Invoices";
import ClientsPage from "../pages/clients/Clients";
import EmailPage from "../pages/email/EmailPage";
import AutomationRoutes from "./automationRoutes";
import UserRoutes from "./userRoutes";
import {getUserRolesFromToken} from "../helpers/token/processToken";
import {setUserRoles} from "../store/user/userSlice";
import ManageShopifyPage from "../pages/shopify/manage/ManageShopifyPage";
import AdminHomePage from "../pages/home/admin_home/AdminHome";
import QuickbooksBillingPage from "../pages/quickbooks_billing/QuickbooksBilling";
import ZohoBillingPage from "../pages/zoho_billing/ZohoBilling";
import {AuthProvider, RequireAuth} from "../helpers/auth/authHelper";


/**
 * @component AppRoutes
 * @description Represents the main component responsible for handling authentication and routing based on user roles.
 * The component handles multiple authentication flows and dynamically sets routes that are available to the user based on their roles.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.instance - An instance object required for handling specific authentication requests.
 * @returns {JSX.Element} - The JSX for the routing and authentication flow management.
 */
const AppRoutes = ({instance}) => {
    const userRoles = useSelector((state) => state.user.userRoles);
    const dispatch = useDispatch();

    /**
     * useEffect hook to populate roles from token, then set rolesInitialized to true.
     */
    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            const roles = getUserRolesFromToken(token);
            dispatch(setUserRoles(roles));
        }
    }, [dispatch]);


    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <AuthProvider>
                            <RequireAuth>
                                <AdminHomePage/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />
                <Route
                    path="/screen/review"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Labb.Specimen.Details"]}>
                                <ScreenPage/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />
                <Route
                    path="/laboratory"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.Laboratory"]}>
                                <LaboratoryPage/>
                            </RequireAuth>

                        </AuthProvider>
                    }
                />

                <Route
                    path="/medical-review-officer"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Labb.Mro.Reports"]}>
                                <MedicalReviewOfficerPage/>
                            </RequireAuth>

                        </AuthProvider>
                    }
                />

                <Route
                    path="/zoho/billing"
                    element={

                        <AuthProvider>
                            <RequireAuth requiredRoles={["Labb.Billing.Reports"]}>
                                <ZohoBillingPage/>
                            </RequireAuth>

                        </AuthProvider>
                    }
                />

                <Route
                    path="/quickbooks/billing"
                    element={

                        <AuthProvider>
                            <RequireAuth requiredRoles={["Labb.Billing.Reports"]}>
                                <QuickbooksBillingPage/>
                            </RequireAuth>

                        </AuthProvider>
                    }
                />

                <Route
                    path="/invoices"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.Billing.Invoice.Reports"]}>
                                <InvoicesPage/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/clients"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Labb.Client.Management"]}>
                                <ClientsPage userRoles={userRoles}/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/orders"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.Orders"]}>
                                <OrdersPage userRoles={userRoles}/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/badganator"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.Badganator"]}>
                                <BadganatorPage/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/automation/*"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.Autobot", "Freddy.SuperAdmin"]}>
                                <AutomationRoutes instance={instance}/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/users/*"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.SuperAdmin"]}>
                                <UserRoutes instance={instance}/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/email"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Labb.Email"]}>
                                <EmailPage instance={instance}/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />

                <Route
                    path="/shopify"
                    element={
                        <AuthProvider>
                            <RequireAuth requiredRoles={["Freddy.Manage.Shopify"]}>
                                <ManageShopifyPage instance={instance}/>
                            </RequireAuth>
                        </AuthProvider>
                    }
                />


            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
