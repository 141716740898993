import React from "react";
import "./SpecimenNote.css";
import formatDate from "../../../../../utils/dateUtils";

export default function SpecimenNote({ note }) {
    return (
        <div className="specimen-note">
            <div className="specimen-note-header">
                <span className="specimen-note-subject">{note.subject || ""}</span>
                <span className="specimen-note-timestamp">
                    {note.created_at ? formatDate(note.created_at) : "Unknown Date"}
                </span>
            </div>
            <div className="specimen-note-description">
                {note.note || "No details available."}
            </div>
        </div>
    );
}
