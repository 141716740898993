import React, { useEffect, useRef, useState } from "react";
import "./SpecimenNotes.css";
import SpecimenNote from "./specimen_note/SpecimenNote";
import { IconButton } from "../../../buttons/icon_button/IconButton";
import { GenericIcons } from "../../../../assets/icons";
import {fetchSaveNoteScreen} from "../../../../services/api/v1/laboratoryService";
import {useDispatch} from "react-redux";
import {saveNoteScreen} from "../../../../store/laboratory/laboratorySlice";

export default function SpecimenNotes({ screenDetails, notes }) {
    const [newNote, setNewNote] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [allNotes, setAllNotes] = useState(notes || []); // Local state for notes
    const firstNoteRef = useRef(null);
    const notesContainerRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        // Auto-scroll to the first note on mount within the notes container
        if (firstNoteRef.current && notesContainerRef.current) {
            const container = notesContainerRef.current;
            const firstNote = firstNoteRef.current;

            const containerTop = container.getBoundingClientRect().top;
            const firstNoteTop = firstNote.getBoundingClientRect().top;

            container.scrollTop += firstNoteTop - containerTop;
        }
    }, [notes]);

    const handleInputChange = (e) => {
        setNewNote(e.target.value);
    };

    const saveNote = () => {
        if (newNote.trim() === "") {
            alert("Note cannot be empty.");
            return;
        }

        setIsSaving(true);
        dispatch(saveNoteScreen(
            {
                screenId: screenDetails.screen_id,
                note: newNote
            }
        ))
        setIsSaving(false);
        setNewNote("");
        setAllNotes([{ note: newNote }, ...allNotes]);

    };

    return (
        <div
            className="specimen-notes-container"
            ref={notesContainerRef}
        >
            <div className="note-input-container">
                <input
                    type="text"
                    value={newNote}
                    onChange={handleInputChange}
                    placeholder="Add a new note..."
                    disabled={isSaving}
                />
                <IconButton
                    defaultIcon={GenericIcons.CheckIcon}
                    hoverIcon={GenericIcons.CheckIcon}
                    onClick={saveNote}
                    disabled={isSaving}
                    className="save-note-btn"
                />
            </div>
            {allNotes.length === 0 ? (
                <div className="no-notes"></div>
            ) : (
                allNotes.map((note, index) => (
                    <div
                        key={index}
                        ref={index === allNotes.length - 1 ? firstNoteRef : null} // Reference the first note
                        className="specimen-note"
                    >
                        <SpecimenNote note={note} />
                    </div>
                ))
            )}
        </div>
    );
}
