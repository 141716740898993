// freddy-react/src/components/screens/header_row/HeaderRow.js

import React from 'react';
import './HeaderRow.css';

const HeaderRow = () => {
    return (
        <div>
        <div className="organizations-table-header-row">
            <div className="organizations-table-header-cell">Organization Name</div>
            <div className="organizations-table-header-cell">Account Number</div>
            <div className="organizations-table-header-cell">DOT Account Number</div>
            <div className="organizations-table-header-cell">DER Contact</div>
            <div className="organizations-table-header-cell">DER Telephone</div>
            <div className="organizations-table-header-cell">Address</div>
            <div className="organizations-table-header-cell">City</div>
            <div className="organizations-table-header-cell">State</div>
            <div className="organizations-table-header-cell">Zip</div>
            <div className="organizations-table-header-cell">Actions</div>
        </div>
        </div>
    );
};

export default HeaderRow;
