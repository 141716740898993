import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Badganator.css';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { GenericIcons } from "../../assets/icons";
import { IconButton } from "../buttons/icon_button/IconButton";
import { createCollectorBadge } from "../../store/badganator/badganatorSlice";
import { showMessageModal } from "../../store/modals/modalsSlice";
import { fetchClientScreenersDetails, fetchThinkificCollectorDetails } from "../../store/screener/screenerSlice";
import { PrimaryButton } from "../buttons/primary_button/PrimaryButton";

const Badganator = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // Start with null so that no collector is selected by default
    const [selectCollectorOptions, setSelectCollectorOptions] = useState(null);
    const [shouldDownload, setShouldDownload] = useState(false);
    const [collectorBadgeUrl, setCollectorBadgeUrl] = useState("");
    const [overrideApproval, setOverrideApproval] = useState(false);
    const [badgeError, setBadgeError] = useState(false);

    const clientId = useSelector(state => state.badganator.clientId);
    const clientCollectors = useSelector(state => state.screeners.screeners);
    const [isBadgeApproved, setIsBadgeApproved] = useState(false);
    const badgeMessage = useSelector(state => state.screeners.message);
    const [isDirectReadApproved, setIsDirectReadApproved] = useState(false);

    useEffect(() => {
        if (clientId) {
            dispatch(fetchClientScreenersDetails({ clientId }));
        }
    }, [clientId, dispatch]);

    const handleCollectorChange = async (selectedOption) => {
        setSelectCollectorOptions(selectedOption);

        if (!selectedOption) return; // If nothing is selected, do nothing

        try {
            setLoading(true);
            // Fetch the collector details to check badge and direct-read approval
            const collectorId = selectedOption.value;
            const result = await dispatch(fetchThinkificCollectorDetails({ collectorId })).unwrap();
            setLoading(false);

            if (result.is_direct_read_approved !== null) {
                setIsDirectReadApproved(result.is_direct_read_approved);
            }
            if (result.is_badge_approved !== null) {
                setIsBadgeApproved(result.is_badge_approved);
            }

            // If the badge is not approved, show an error message
            if (!result.is_badge_approved) {
                setBadgeError(true);
                dispatch(showMessageModal({ message: badgeMessage || "Badge not approved.", link: null }));
            } else {
                setBadgeError(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch collector details:', error);
            dispatch(showMessageModal({ message: "Failed to fetch collector details.", link: null }));
        }
    };

    const submitCollectorOptions = async () => {
        const collectorId = selectCollectorOptions.value;
        const formData = new FormData();
        formData.append('collector_id', collectorId);
        try {
            const response = await dispatch(createCollectorBadge({ formData })).unwrap();
            setCollectorBadgeUrl(response[0]); // Assuming the response contains a URL
            setShouldDownload(true);
        } catch (error) {
            console.error('Failed to create badge:', error);
            dispatch(showMessageModal({ message: "Failed to create badge.", link: null }));
        }
    };

    const downloadCollectorBadge = (e) => {
        e.preventDefault();
        submitCollectorOptions();
    };

    useEffect(() => {
        if (collectorBadgeUrl && shouldDownload) {
            const anchor = document.createElement('a');
            anchor.href = collectorBadgeUrl;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            setCollectorBadgeUrl("");
            setShouldDownload(false);
            dispatch(showMessageModal({ message: "Download successful.", link: null }));
        }
    }, [collectorBadgeUrl, shouldDownload, dispatch]);

    // Map through collectors and include additional details for the details bubble.
    const collectorOptions = clientCollectors.map(clientCollector => ({
        value: clientCollector.collector_id,
        label: `${clientCollector.collector_first_name} ${clientCollector.collector_last_name}`,
        collector_email: clientCollector.collector_email,
        collector_phone: clientCollector.collector_phone,
        can_manage_panels: clientCollector.can_manage_panels,
        can_manage_results: clientCollector.can_manage_results,
        can_order_cartons: clientCollector.can_order_cartons,
        can_manage_screeners: clientCollector.can_manage_screeners,
        can_perform_screens: clientCollector.can_perform_screens,
        is_account_owner: clientCollector.is_account_owner
    }));

    return (
        <div className="badganator-main-frame">
            <div className="badganator-header">
                <div className="badganator-collector-selector">
                    <CreatableSelect
                        components={makeAnimated()}
                        placeholder="Select User..."
                        className="multi-selector-user"
                        closeMenuOnSelect={true}
                        options={collectorOptions}
                        onChange={handleCollectorChange}
                        value={selectCollectorOptions}
                        // Render the menu at the document.body level using a fixed position
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            menu: provided => ({
                                ...provided,
                                top: 'calc(100% + 5px)', // Slight offset so it doesn't conflict with the control
                            }),
                        }}
                    />
                </div>
                <div className="button-container">
                    {!isBadgeApproved && badgeError && (
                        <PrimaryButton onClick={() => setOverrideApproval(true)}>
                            OVERRIDE
                        </PrimaryButton>
                    )}
                    <div className="pre-set-report-icon-frame">
                        {selectCollectorOptions && (isBadgeApproved || overrideApproval) && (
                            <IconButton
                                onClick={downloadCollectorBadge}
                                hoverIcon={GenericIcons.DownloadIcon}
                                defaultIcon={GenericIcons.DownloadIcon}
                            />
                        )}
                    </div>
                    <div className="pre-set-report-icon-frame">
                        {selectCollectorOptions && isDirectReadApproved && (
                            <IconButton
                                hoverIcon={GenericIcons.PersonIcon}
                                defaultIcon={GenericIcons.PersonIcon}
                            />
                        )}
                    </div>
                </div>
            </div>
            {selectCollectorOptions && (
                <div className="collector-details-bubble">
                    <p><strong>{selectCollectorOptions.label}</strong></p>
                    <p>Email: {selectCollectorOptions.collector_email || "N/A"}</p>
                    <p>Phone: {selectCollectorOptions.collector_phone || "N/A"}</p>
                    <div className="collector-permissions">
                        <label>
                            <input
                                type="checkbox"
                                checked={selectCollectorOptions.can_manage_panels || false}
                                readOnly
                            /> Can Manage Panels
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectCollectorOptions.can_manage_results || false}
                                readOnly
                            /> Can Manage Results
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectCollectorOptions.can_order_cartons || false}
                                readOnly
                            /> Can Order Cartons
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectCollectorOptions.can_manage_screeners || false}
                                readOnly
                            /> Can Manage Screeners
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectCollectorOptions.can_perform_screens || false}
                                readOnly
                            /> Can Perform Screens
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectCollectorOptions.is_account_owner || false}
                                readOnly
                            /> Is Account Owner
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Badganator;
