import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {requestQuestOrders} from "../../services/api/v1/orderQuestServices";


export const requestQuestOrder = createAsyncThunk(
    'questOrders/requestQuestOrder',
    async ({questOrder}, thunkAPI) => {
        const accessToken = sessionStorage.getItem("shopifyAccessToken");
        return await requestQuestOrders(accessToken, questOrder);
    }
);

const questOrdersSlice = createSlice({
    name: 'questOrders',
    initialState: {
        isQuestOrderDetailsVisible: false,
        questOrderRequests: [],
        selectedOrganization: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        // Clears the quest orders data
        clearQuestOrdersData: (state) => {
            state.questOrders = [];
        },
        // Toggles the visibility of the quest order modal
        toggleQuestOrderDetailModal: (state) => {
            state.isQuestOrderDetailsVisible = !state.isQuestOrderDetailsVisible;
        },
        // Sets the selected organization data for display in the modal
        setOrderSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestQuestOrder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestQuestOrder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.questOrderRequests = action.payload || [];
            })
            .addCase(requestQuestOrder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the actions to be used in components
export const { toggleQuestOrderDetailModal, setOrderSelectedOrganization } = questOrdersSlice.actions;

export default questOrdersSlice.reducer;